// Font weights
$extra-bold: 900;
$bold: 700;
$medium: 500;
$regular: 400;
$light: 300;



h1, %h1 {
  font-size: 2.25rem; //40px
}

h2, %h2 {
  font-size: 2rem; //32px

}

h3, %h3 {
  font-size: 1.5rem; //24px
}

h4, %h4 {
  font-size: 1.125rem; //18px
}

h5, %h5 {
  font-size: 1rem; //16px
}



p, .p {
  font-size: 1rem;
}

strong {
  font-weight: $medium;
}

@media screen and (max-width: $phone-landscape-max) {
  h1, %h1 {
    font-size: 1.75rem;
  }

  h4, %h4 {
    font-size: 1.5rem;
  }
}
