@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    @include transform(translateX(-50%) translateY(-50%));
  }
  @else if $xy == x {
    left: 50%;
    right: auto;
    @include transform(translateX(-50%));
  }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    @include transform(translateY(-50%));
  }
}

// antialiasing mode font rendering

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




/*
 * Ellipsis title
 *
 * Example of usage:
 * @include ellipsis(2em, 2);
 *
 */

@mixin ellipsis ($max-height, $lines: 2) {
  overflow: hidden;
  max-height: $max-height;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;  
  text-overflow: -o-ellipsis-lastline;
}


/*
 * Calc
 */
@mixin calc($property, $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
}


@mixin trans($val...) {
   -webkit-transition: $val;
   -moz-transition: $val; 
   -o-transition: $val; 
   transition: $val; 
}



/* Font Size */
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}


/* Button */
@mixin button-line($color, $background, $border) {
  color: $color;
  background-color: rgba($background, 0);
  border-color: $border;

  &:focus,
  &.focus {
    color: $background;
    background-color: $color;
    border-color: $border;
  }
  &:hover {
    color: $background;
    background-color: $color;
    border-color: $border;
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $background;
    background-color: $color;
    border-color: $border;

    &:hover,
    &:focus,
    &.focus {
      color: $background;
      background-color: $color;
      border-color: $border;
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
       color: $background;
       background-color: $color;
       border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}