
.fp-slidesNav {
	@include center(x);
	margin-left: 0px !important;
	&.bottom{
		bottom: 30px;
	}
	ul{
		li{
			width: 15px;
			height: 15px;
			a{
				span{
					background-color: transparent;
					width: 15px;
					height: 15px;
					border: 2px solid $base-color-white;
					margin: 0;
					.fp-viewing-desktop-1 &{
						border: 2px solid $base-color-black;
					}
				}
				&.active{
					span{
						background-color: $base-color-white;
						width: 15px;
						height: 15px;
						border: 2px solid $base-color-white;
						margin: 0;
						.fp-viewing-desktop-1 &{
							border: 2px solid $base-color-black;
							background-color: $base-color-black;
						}
					}
				}
			}
			&:hover{
				a{
					span{
						background-color: transparent;
						width: 15px;
						height: 15px;
						border: 2px solid $base-color-white;
						margin: 0;
						.fp-viewing-desktop-1 &{
							border: 2px solid $base-color-black;
						}
					}
					&.active{
						span{
							background-color: $base-color-white;
							width: 15px;
							height: 15px;
							border: 2px solid $base-color-white;
							margin: 0;
							.fp-viewing-desktop-1 &{
								border: 2px solid $base-color-black;
								background-color: $base-color-black;
							}
						}
					}
				}
			}
		}
	}
}




#fp-nav{
	background: $base-color-white;
	@include box-shadow(0 2px 4px 0 rgba(0,0,0,0.50));
	@include border-radius(30px);
	width: 34px;
	padding: 10px 4px;
	ul{
		li{
			margin:15px 7px;
			&:last-child{
				display: none;
			}
			a{
				span{
					width: 12px;
					height: 12px;
					margin: 0;
					background-color: transparent;
					border: 3px solid #979797;
					top: 0;
					left: 0;
				}
				&.active{
					span{
						margin: 0;
						background-color: transparent;
						border: 3px solid $base-color-pink;
					}
				}


			}

			&:hover{
				a{
					&,
					&.active{
						span{
							width: 12px;
							height: 12px;
							top: 0;
							left: 0;
							margin:0;
							border: 3px solid $base-color-pink;
						}
					}
					
				}
				
			}

			.fp-tooltip{
				background-color: $base-color-pink;
				@include border-radius(3px);
				padding: 5px 10px;
				top: -10px;
				font-family: $font-primary;
				overflow: inherit;

				&.right{
					right: 32px;
					&:before{
						content: '';
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 5px 0 5px 5px;
						border-color: transparent transparent transparent $base-color-pink;
						position: absolute;
						@include center(y);
						right: -5px;

					}
				}
			}
		}
	}


}

@media screen and (max-width: $phone-landscape-max) {
	#fp-nav{
		display: none;
	}

	.fp-slidesNav {
		left:50%;
		ul{
			li{
				width: 10px;
				height: 10px;
				a{
					span{
						background-color: transparent;
						width: 10px;
						height: 10px;
						border: 2px solid $base-color-white;
						margin: 0;

					}
					&.active{
						span{
							background-color: $base-color-white;
							width: 10px;
							height: 10px;
							border: 2px solid $base-color-white;
							margin: 0;
						}
					}
				}
				&:hover{
					a{
						span{
							background-color: transparent;
							width: 10px;
							height: 10px;
							border: 2px solid $base-color-white;
							margin: 0;
						}
						&.active{
							span{
								background-color: $base-color-white;
								width: 10px;
								height: 10px;
								border: 2px solid $base-color-white;
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
}