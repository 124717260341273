.section{
	position: relative;
	overflow: hidden;

	.container{
		position: relative;
		z-index: 20;
	}
	.fp-bg{
		top: 0;
		bottom: 0;
		width: 100%;
		position: absolute;
		z-index: -2;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}
	
	h2{
		@include font-size(50px);
		color: $base-color-black;
		letter-spacing: -3.01px;
		line-height: 63px;
		margin-bottom: 30px;
		font-weight: $extra-bold;
	}

	h6{

		@include font-size(19px);
		color: $base-color-black;
		text-transform: uppercase;
		font-weight: $bold;
		margin-bottom: 5px;
	}

	p{
		&.text-info{
			/* The artist is a coll: */
			opacity: 0.8;
			@include font-size(24px);
			color: $base-color-black;
			letter-spacing: 0;
			line-height: 36px;
		}
	}

	.circle{
		width: 830px;
		height: 830px;
		@include border-radius(50%);
		display: block;
		position: absolute;
		right: -20%;
		top: -70%;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		z-index: -1;
	}

	.slide{
		position: relative;
		overflow: hidden;
	}

	&--hero{
		background-color: $section-color-hero;
		.img-intro{
			margin-bottom: 50px;
			max-width: 550px;
		}
		blockquote{
			max-width: 626px;
			display: block;
			margin: 0 auto;
			position: relative;
			&:before{
				border-left: 3px solid $base-color-pink;
				content: '';
				position: absolute;
				left: -10px;
				top: 4px;
				bottom: 4px;
			}
			
			p{
				&.text-intro{
					@include font-size(20px);
					color: $base-color-black;
					letter-spacing: 0;
					line-height: 30px;
					font-weight: $medium;
					span{
						color: $base-color-pink;
					}
				}
				
			}
		}
	}

	&--hero-landing{
		background-image: linear-gradient(-225deg, #A12A33 0%, #CA464F 54%, #E85A64 100%);
		&:before{
			content: '';
			position: absolute;
			left: 0;
			bottom: -50%;
			right: 0;
			height: 630px;
			@include retina('../img/bkg_hero_landing.png', 2, auto 630px, center center no-repeat);
		}
		

		h2{
			color: $base-color-white;
		}

		p{
			@include font-size(20px);
			color: $base-color-white;
		}

		#tel-la, #location-la,
		#tel-arg, #location-arg,
		#tel-chi, #location-chi
		 {
    		display:none;
		}

		.block-form-hero{
			background-color: $base-color-white;
			position: fixed;
			-webkit-perspective: 1000;
			right: 0;
			top: 0;
			height: 100vh;
			max-width: 500px;
			width: 100%;
			padding-left: 60px;
			padding-right: 80px;
			padding-top: 40px;
			h3{
				color: $base-color-red;
				@include font-size(36px);
				font-weight: $bold;
			}
			.form-contact{
				margin-top: 20px;
				max-width: 300px;
				.form-group{
					margin-bottom: 18px;
					position: relative;
					.control-label{
						@include font-size(14px);
						position: absolute;
						left: 12px;
						top: 3px;
						@include transition(top 0.3s ease);
						span{
							color: $base-color-red;
							@include font-size(13px);
							@include transition(opacity 0.3s ease);
						}
					}
					.form-control{
						background-color: transparent;
						@include box-shadow(none);
						@include border-radius(0);
						border: none;
						border-bottom: 1px solid $base-color-black;
						resize: none;
						color: $base-color-black;
						@include font-size(16px);
						letter-spacing: 0;
						@include input-placeholder{
							color: $base-color-black;
						}
					}


					&.with-focus,
					&.with-value{
						.control-label{
							top: -16px;
							@include font-size(12px);
							
						}
					}


					&.with-value{
						.control-label{
							span{
								@include opacity(0);
							}
						}
					}


				}

				.btn{
					@include font-size(13px);
					text-transform: uppercase;
					width: 100%;
				}


			}
			.block-contact-phone{
				display: block;
				margin-top: 20px;
				p{
					@include font-size(24px);
					color: #535353;
					letter-spacing: -2.41px;
					font-weight: $bold;
				}

				ul{
					> li{
						margin: 10px 0;
						a{
							@include opacity(0.8);
							@include font-size(24px);
							color: $base-color-black;
							letter-spacing: 0;
							line-height: 40px;
							vertical-align: middle;

							img{
								display: inline-block;
								vertical-align: middle;
								position: relative;
								top: -2px;
							}

							&:hover{
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	
	}

	&--hacemos{
		background-color: rgba($section-color-hacemos, 0.7);
		.circle{
			background: #B9C2D2;
		}

		.block-module-windows{
			position: relative;
			bottom: -100px;
		}

		.block-hacemos-info{
			margin: 40px 0;
			display: block;
			background-color: $base-color-white;
			@include border-radius(5px);
			max-height: 140px;
			padding: 20px 15px;
			max-width: 480px;
			.row{
				margin-left: -10px;
				margin-right: -10px;
				[class*="col-"] {
					padding-left: 10px;
					padding-right: 10px;
				}
			}

			.img-responsive{
				margin-top: 6px;
			}

			h6{
				color: #303030;
				font-weight: $bold;
				letter-spacing: 0;
				line-height: 24px;
				margin-bottom: 0;
			}

			p{
				@include font-size(16px);
				letter-spacing: 0;
				line-height: 26px;
				margin-bottom: 0;
			}

			&.block-info-1{
				margin-top: 100px;
			}
		}
	}
	&--desktop,
	&--mobile{

		/*
		background-color: $section-color-mobile;
		.circle{
			background: #F0281F;
		}
		*/


	
	
		/*
		background-color: $section-color-desktop;
		.circle{
			background: #4B4B57;
		}

		*/
		h2,h6,p.text-info{
			color: $base-color-white;
		}


		h6{
			margin-top: -40px;
		}

		.logo-client{
			margin: 20px 0;
		}

		.block-module-notebook{
			//min-height: 500px;
			position: relative;
		}

		.slide{
			// Desktop Client
			&--wallmart{
				//background-color: $client-wallmart;
				.fp-bg{
					background-color: $client-wallmart;
				}
				.circle{
					background:darken($client-wallmart, 5);
				}
			}

		
			&--zurich{
				//background-color: $base-color-white;
				.fp-bg{
					background-color: $base-color-white;
				}
				.circle{
					background: $client-zurich;
				}

				h2,h6,p.text-info{
					color: $base-color-black;
				}
			}


			
			&--hafele{
				//background: darken( $client-hafele, 10);
				.fp-bg{
					background: darken( $client-hafele, 20);
				}
				.circle{
					background: darken( $client-hafele, 5);
				}
			}

			// Mobile Client
			&--santander{
				//background-color: $client-santander;
				.fp-bg{
					background-color: $client-santander;
				}
				.circle{
					//background: #F0281F;
					background: darken($client-santander, 5);
				}
			}


			&--foodcloud{
				//background-color: $client-foodcloud;
				.fp-bg{
					background-color: $client-foodcloud;
				}
				.circle{
					background: darken($client-foodcloud, 5);
				}
			}

			&--scholas{
				//background-color: $client-scholas;
				.fp-bg{
					background-color: $client-scholas;
				}
				.circle{
					background: darken($client-scholas, 5);
				}
			}		
			
		}
		
	
	}


	&--clientes{
		background-color: $section-color-clientes;
		background-image: url('../img/bkg_clientes.jpg');
		background-repeat: no-repeat;
		background-position: 50% 0;
		@include background-size(cover);
		&:before {
			content: ' ';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			@include opacity(0.6);
			background-color: $base-color-black;
			
		}
		h2,h6,p.text-info{
			color: $base-color-white;
		}

		.container{
			position: relative;
			z-index: 2;
		}
	}


	&--contacto{
		background-color: $section-color-contacto;
		.circle{
			background: #E4E4EB;
		}

		.form-contact{
			max-width: 500px;
			.form-group{
				margin-bottom: 30px;
				position: relative;
				.control-label{
					@include font-size(18px);
					position: absolute;
					left: 12px;
					top: 3px;
					@include transition(top 0.3s ease);
					span{
						color: $base-color-red;
						@include font-size(13px);
						@include transition(opacity 0.3s ease);
					}
				}
				.form-control{
					background-color: transparent;
					@include box-shadow(none);
					@include border-radius(0);
					border: none;
					border-bottom: 1px solid $base-color-black;
					resize: none;
					color: $base-color-black;
					@include font-size(18px);
					letter-spacing: 0;
					@include input-placeholder{
						color: $base-color-black;
					}
				}


				&.with-focus,
				&.with-value{
					.control-label{
						top: -16px;
						@include font-size(14px);
						
					}
				}


				&.with-value{
					.control-label{
						span{
							@include opacity(0);
						}
					}
				}


			}

			.btn{
				float: right;
				@include font-size(13px);
				text-transform: uppercase;
			}


		}
		
		.block-msg-form{
			text-align: center;
			padding: 20px 0;
			p{
				@include font-size(18px);
				font-weight: $medium;
			}
		}

		.block-contact-phone{
			display: block;
			margin-top: 40px;
			h3{
				@include font-size(48px);
				color: $base-color-black;
				letter-spacing: -2.41px;
				line-height: 63px;
				font-weight: $extra-bold;
			}

			ul{
				margin-top: 90px;
				> li{
					margin: 10px 0;
					a{
						@include opacity(0.8);
						@include font-size(24px);
						color: $base-color-black;
						letter-spacing: 0;
						line-height: 40px;
						vertical-align: middle;

						img{
							display: inline-block;
							vertical-align: middle;
							position: relative;
							top: -2px;
						}

						&:hover{
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}


@media screen and (max-width: $phone-landscape-max) {
	.section{
		.fp-tableCell{
			padding: 80px 0;
		}
		
		h2{
			@include font-size(36px);
			line-height: 46px;
			text-align: center;
			letter-spacing: -1.8px;
		}

		h6{

			@include font-size(14px);
			text-align: center;
		}

		p{
			&.text-info{
				/* The artist is a coll: */
				opacity: 0.8;
				@include font-size(18px);
				line-height: 23px;
				text-align: center;
			}
		}

		.slide{
			position: static;
			.circle{
				display: none;
			}
		}

		&--hero{
			.img-intro{
				max-width: 100%;
			}


			blockquote{
				text-align: center;
				&:before{
					display: none;
				}
				
				
			}
			
		}

		&--hero-landing{
			.fp-tableCell{
				padding: 140px 0 0;
			}
			.block-form-hero{
				position: relative;
				margin-top: 40px;
				margin-left: -15px;
				margin-right: -15px;
				padding: 40px 15px;
				display: block;
				@include calc('width', '100% + 30px');
				.form-contact{
					max-width: inherit;
				}
			}
		}

		&--hacemos{
			p{
				&.text-info{
					margin-bottom: 40px;
				}
			}
			.block-hacemos-info{
				margin: 20px 0;
				display: block;
				background-color: transparent;
				max-height: inherit;
				padding: 0;
				text-align: center;
				

				.img-responsive{
					margin-bottom: 10px;
				}

				h6{
					color: #303030;
					font-weight: $bold;
					letter-spacing: 0;
					line-height: 24px;
					margin-bottom: 0;
				}

				p{
					@include font-size(16px);
					letter-spacing: 0;
					line-height: 26px;
					margin-bottom: 0;
				}

				&.block-info-1{
					margin-top: 0;
				}
			}

			

			.block-module-windows{
				bottom: -80px;
				
			}
		}

		&--mobile,
		&--desktop{
			height: auto!important;
			.logo-client{
				margin: 40px auto 20px;
			}


			.block-module-notebook{
				margin-top: 40px;
			}
		}

		&--contacto{
			

			.form-contact{
				
				.btn{
					float: none;
					width: 240px;
					margin: 0 auto;
					display: block;
				}

			}
			

			.block-contact-phone{
				
				h3{
					text-align: center;
					@include font-size(24px);
				}

				ul{
					margin-top: 20px;
					max-width: 200px;
					margin-left: auto;
					margin-right: auto;
					> li{
						> a{
							@include font-size(18px);
						}
					}
					
				}
			}


		}
	}

}