html, body {
  background-color: $base-color-white;
  color: $base-color-black;
  font-family: $font-primary;
  font-size: 16px;
  font-weight: $light;
  height: 100%;
  line-height: 1.5;
  position: relative;
  &.overflow-hidden {
    /* when primary navigation is visible, the content in the background won't scroll */
    overflow: hidden!important;
  }
}

a, a:hover {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
  image-rendering: -moz-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

@mixin clearfix {
  &:after {
    clear: both;
    content: ' ';
    display: block;
  }
}
