.#{$linearico-css-prefix}-home:before {
	content: "\e600";
}
.#{$linearico-css-prefix}-home2:before {
	content: "\e601";
}
.#{$linearico-css-prefix}-home3:before {
	content: "\e602";
}
.#{$linearico-css-prefix}-home4:before {
	content: "\e603";
}
.#{$linearico-css-prefix}-home5:before {
	content: "\e604";
}
.#{$linearico-css-prefix}-home6:before {
	content: "\e605";
}
.#{$linearico-css-prefix}-bathtub:before {
	content: "\e606";
}
.#{$linearico-css-prefix}-toothbrush:before {
	content: "\e607";
}
.#{$linearico-css-prefix}-bed:before {
	content: "\e608";
}
.#{$linearico-css-prefix}-couch:before {
	content: "\e609";
}
.#{$linearico-css-prefix}-chair:before {
	content: "\e60a";
}
.#{$linearico-css-prefix}-city:before {
	content: "\e60b";
}
.#{$linearico-css-prefix}-apartment:before {
	content: "\e60c";
}
.#{$linearico-css-prefix}-pencil:before {
	content: "\e60d";
}
.#{$linearico-css-prefix}-pencil2:before {
	content: "\e60e";
}
.#{$linearico-css-prefix}-pen:before {
	content: "\e60f";
}
.#{$linearico-css-prefix}-pencil3:before {
	content: "\e610";
}
.#{$linearico-css-prefix}-eraser:before {
	content: "\e611";
}
.#{$linearico-css-prefix}-pencil4:before {
	content: "\e612";
}
.#{$linearico-css-prefix}-pencil5:before {
	content: "\e613";
}
.#{$linearico-css-prefix}-feather:before {
	content: "\e614";
}
.#{$linearico-css-prefix}-feather2:before {
	content: "\e615";
}
.#{$linearico-css-prefix}-feather3:before {
	content: "\e616";
}
.#{$linearico-css-prefix}-pen2:before {
	content: "\e617";
}
.#{$linearico-css-prefix}-pen-add:before {
	content: "\e618";
}
.#{$linearico-css-prefix}-pen-remove:before {
	content: "\e619";
}
.#{$linearico-css-prefix}-vector:before {
	content: "\e61a";
}
.#{$linearico-css-prefix}-pen3:before {
	content: "\e61b";
}
.#{$linearico-css-prefix}-blog:before {
	content: "\e61c";
}
.#{$linearico-css-prefix}-brush:before {
	content: "\e61d";
}
.#{$linearico-css-prefix}-brush2:before {
	content: "\e61e";
}
.#{$linearico-css-prefix}-spray:before {
	content: "\e61f";
}
.#{$linearico-css-prefix}-paint-roller:before {
	content: "\e620";
}
.#{$linearico-css-prefix}-stamp:before {
	content: "\e621";
}
.#{$linearico-css-prefix}-tape:before {
	content: "\e622";
}
.#{$linearico-css-prefix}-desk-tape:before {
	content: "\e623";
}
.#{$linearico-css-prefix}-texture:before {
	content: "\e624";
}
.#{$linearico-css-prefix}-eye-dropper:before {
	content: "\e625";
}
.#{$linearico-css-prefix}-palette:before {
	content: "\e626";
}
.#{$linearico-css-prefix}-color-sampler:before {
	content: "\e627";
}
.#{$linearico-css-prefix}-bucket:before {
	content: "\e628";
}
.#{$linearico-css-prefix}-gradient:before {
	content: "\e629";
}
.#{$linearico-css-prefix}-gradient2:before {
	content: "\e62a";
}
.#{$linearico-css-prefix}-magic-wand:before {
	content: "\e62b";
}
.#{$linearico-css-prefix}-magnet:before {
	content: "\e62c";
}
.#{$linearico-css-prefix}-pencil-ruler:before {
	content: "\e62d";
}
.#{$linearico-css-prefix}-pencil-ruler2:before {
	content: "\e62e";
}
.#{$linearico-css-prefix}-compass:before {
	content: "\e62f";
}
.#{$linearico-css-prefix}-aim:before {
	content: "\e630";
}
.#{$linearico-css-prefix}-gun:before {
	content: "\e631";
}
.#{$linearico-css-prefix}-bottle:before {
	content: "\e632";
}
.#{$linearico-css-prefix}-drop:before {
	content: "\e633";
}
.#{$linearico-css-prefix}-drop-crossed:before {
	content: "\e634";
}
.#{$linearico-css-prefix}-drop2:before {
	content: "\e635";
}
.#{$linearico-css-prefix}-snow:before {
	content: "\e636";
}
.#{$linearico-css-prefix}-snow2:before {
	content: "\e637";
}
.#{$linearico-css-prefix}-fire:before {
	content: "\e638";
}
.#{$linearico-css-prefix}-lighter:before {
	content: "\e639";
}
.#{$linearico-css-prefix}-knife:before {
	content: "\e63a";
}
.#{$linearico-css-prefix}-dagger:before {
	content: "\e63b";
}
.#{$linearico-css-prefix}-tissue:before {
	content: "\e63c";
}
.#{$linearico-css-prefix}-toilet-paper:before {
	content: "\e63d";
}
.#{$linearico-css-prefix}-poop:before {
	content: "\e63e";
}
.#{$linearico-css-prefix}-umbrella:before {
	content: "\e63f";
}
.#{$linearico-css-prefix}-umbrella2:before {
	content: "\e640";
}
.#{$linearico-css-prefix}-rain:before {
	content: "\e641";
}
.#{$linearico-css-prefix}-tornado:before {
	content: "\e642";
}
.#{$linearico-css-prefix}-wind:before {
	content: "\e643";
}
.#{$linearico-css-prefix}-fan:before {
	content: "\e644";
}
.#{$linearico-css-prefix}-contrast:before {
	content: "\e645";
}
.#{$linearico-css-prefix}-sun-small:before {
	content: "\e646";
}
.#{$linearico-css-prefix}-sun:before {
	content: "\e647";
}
.#{$linearico-css-prefix}-sun2:before {
	content: "\e648";
}
.#{$linearico-css-prefix}-moon:before {
	content: "\e649";
}
.#{$linearico-css-prefix}-cloud:before {
	content: "\e64a";
}
.#{$linearico-css-prefix}-cloud-upload:before {
	content: "\e64b";
}
.#{$linearico-css-prefix}-cloud-download:before {
	content: "\e64c";
}
.#{$linearico-css-prefix}-cloud-rain:before {
	content: "\e64d";
}
.#{$linearico-css-prefix}-cloud-hailstones:before {
	content: "\e64e";
}
.#{$linearico-css-prefix}-cloud-snow:before {
	content: "\e64f";
}
.#{$linearico-css-prefix}-cloud-windy:before {
	content: "\e650";
}
.#{$linearico-css-prefix}-sun-wind:before {
	content: "\e651";
}
.#{$linearico-css-prefix}-cloud-fog:before {
	content: "\e652";
}
.#{$linearico-css-prefix}-cloud-sun:before {
	content: "\e653";
}
.#{$linearico-css-prefix}-cloud-lightning:before {
	content: "\e654";
}
.#{$linearico-css-prefix}-cloud-sync:before {
	content: "\e655";
}
.#{$linearico-css-prefix}-cloud-lock:before {
	content: "\e656";
}
.#{$linearico-css-prefix}-cloud-gear:before {
	content: "\e657";
}
.#{$linearico-css-prefix}-cloud-alert:before {
	content: "\e658";
}
.#{$linearico-css-prefix}-cloud-check:before {
	content: "\e659";
}
.#{$linearico-css-prefix}-cloud-cross:before {
	content: "\e65a";
}
.#{$linearico-css-prefix}-cloud-crossed:before {
	content: "\e65b";
}
.#{$linearico-css-prefix}-cloud-database:before {
	content: "\e65c";
}
.#{$linearico-css-prefix}-database:before {
	content: "\e65d";
}
.#{$linearico-css-prefix}-database-add:before {
	content: "\e65e";
}
.#{$linearico-css-prefix}-database-remove:before {
	content: "\e65f";
}
.#{$linearico-css-prefix}-database-lock:before {
	content: "\e660";
}
.#{$linearico-css-prefix}-database-refresh:before {
	content: "\e661";
}
.#{$linearico-css-prefix}-database-check:before {
	content: "\e662";
}
.#{$linearico-css-prefix}-database-history:before {
	content: "\e663";
}
.#{$linearico-css-prefix}-database-upload:before {
	content: "\e664";
}
.#{$linearico-css-prefix}-database-download:before {
	content: "\e665";
}
.#{$linearico-css-prefix}-server:before {
	content: "\e666";
}
.#{$linearico-css-prefix}-shield:before {
	content: "\e667";
}
.#{$linearico-css-prefix}-shield-check:before {
	content: "\e668";
}
.#{$linearico-css-prefix}-shield-alert:before {
	content: "\e669";
}
.#{$linearico-css-prefix}-shield-cross:before {
	content: "\e66a";
}
.#{$linearico-css-prefix}-lock:before {
	content: "\e66b";
}
.#{$linearico-css-prefix}-rotation-lock:before {
	content: "\e66c";
}
.#{$linearico-css-prefix}-unlock:before {
	content: "\e66d";
}
.#{$linearico-css-prefix}-key:before {
	content: "\e66e";
}
.#{$linearico-css-prefix}-key-hole:before {
	content: "\e66f";
}
.#{$linearico-css-prefix}-toggle-off:before {
	content: "\e670";
}
.#{$linearico-css-prefix}-toggle-on:before {
	content: "\e671";
}
.#{$linearico-css-prefix}-cog:before {
	content: "\e672";
}
.#{$linearico-css-prefix}-cog2:before {
	content: "\e673";
}
.#{$linearico-css-prefix}-wrench:before {
	content: "\e674";
}
.#{$linearico-css-prefix}-screwdriver:before {
	content: "\e675";
}
.#{$linearico-css-prefix}-hammer-wrench:before {
	content: "\e676";
}
.#{$linearico-css-prefix}-hammer:before {
	content: "\e677";
}
.#{$linearico-css-prefix}-saw:before {
	content: "\e678";
}
.#{$linearico-css-prefix}-axe:before {
	content: "\e679";
}
.#{$linearico-css-prefix}-axe2:before {
	content: "\e67a";
}
.#{$linearico-css-prefix}-shovel:before {
	content: "\e67b";
}
.#{$linearico-css-prefix}-pickaxe:before {
	content: "\e67c";
}
.#{$linearico-css-prefix}-factory:before {
	content: "\e67d";
}
.#{$linearico-css-prefix}-factory2:before {
	content: "\e67e";
}
.#{$linearico-css-prefix}-recycle:before {
	content: "\e67f";
}
.#{$linearico-css-prefix}-trash:before {
	content: "\e680";
}
.#{$linearico-css-prefix}-trash2:before {
	content: "\e681";
}
.#{$linearico-css-prefix}-trash3:before {
	content: "\e682";
}
.#{$linearico-css-prefix}-broom:before {
	content: "\e683";
}
.#{$linearico-css-prefix}-game:before {
	content: "\e684";
}
.#{$linearico-css-prefix}-gamepad:before {
	content: "\e685";
}
.#{$linearico-css-prefix}-joystick:before {
	content: "\e686";
}
.#{$linearico-css-prefix}-dice:before {
	content: "\e687";
}
.#{$linearico-css-prefix}-spades:before {
	content: "\e688";
}
.#{$linearico-css-prefix}-diamonds:before {
	content: "\e689";
}
.#{$linearico-css-prefix}-clubs:before {
	content: "\e68a";
}
.#{$linearico-css-prefix}-hearts:before {
	content: "\e68b";
}
.#{$linearico-css-prefix}-heart:before {
	content: "\e68c";
}
.#{$linearico-css-prefix}-star:before {
	content: "\e68d";
}
.#{$linearico-css-prefix}-star-half:before {
	content: "\e68e";
}
.#{$linearico-css-prefix}-star-empty:before {
	content: "\e68f";
}
.#{$linearico-css-prefix}-flag:before {
	content: "\e690";
}
.#{$linearico-css-prefix}-flag2:before {
	content: "\e691";
}
.#{$linearico-css-prefix}-flag3:before {
	content: "\e692";
}
.#{$linearico-css-prefix}-mailbox-full:before {
	content: "\e693";
}
.#{$linearico-css-prefix}-mailbox-empty:before {
	content: "\e694";
}
.#{$linearico-css-prefix}-at-sign:before {
	content: "\e695";
}
.#{$linearico-css-prefix}-envelope:before {
	content: "\e696";
}
.#{$linearico-css-prefix}-envelope-open:before {
	content: "\e697";
}
.#{$linearico-css-prefix}-paperclip:before {
	content: "\e698";
}
.#{$linearico-css-prefix}-paper-plane:before {
	content: "\e699";
}
.#{$linearico-css-prefix}-reply:before {
	content: "\e69a";
}
.#{$linearico-css-prefix}-reply-all:before {
	content: "\e69b";
}
.#{$linearico-css-prefix}-inbox:before {
	content: "\e69c";
}
.#{$linearico-css-prefix}-inbox2:before {
	content: "\e69d";
}
.#{$linearico-css-prefix}-outbox:before {
	content: "\e69e";
}
.#{$linearico-css-prefix}-box:before {
	content: "\e69f";
}
.#{$linearico-css-prefix}-archive:before {
	content: "\e6a0";
}
.#{$linearico-css-prefix}-archive2:before {
	content: "\e6a1";
}
.#{$linearico-css-prefix}-drawers:before {
	content: "\e6a2";
}
.#{$linearico-css-prefix}-drawers2:before {
	content: "\e6a3";
}
.#{$linearico-css-prefix}-drawers3:before {
	content: "\e6a4";
}
.#{$linearico-css-prefix}-eye:before {
	content: "\e6a5";
}
.#{$linearico-css-prefix}-eye-crossed:before {
	content: "\e6a6";
}
.#{$linearico-css-prefix}-eye-plus:before {
	content: "\e6a7";
}
.#{$linearico-css-prefix}-eye-minus:before {
	content: "\e6a8";
}
.#{$linearico-css-prefix}-binoculars:before {
	content: "\e6a9";
}
.#{$linearico-css-prefix}-binoculars2:before {
	content: "\e6aa";
}
.#{$linearico-css-prefix}-hdd:before {
	content: "\e6ab";
}
.#{$linearico-css-prefix}-hdd-down:before {
	content: "\e6ac";
}
.#{$linearico-css-prefix}-hdd-up:before {
	content: "\e6ad";
}
.#{$linearico-css-prefix}-floppy-disk:before {
	content: "\e6ae";
}
.#{$linearico-css-prefix}-disc:before {
	content: "\e6af";
}
.#{$linearico-css-prefix}-tape2:before {
	content: "\e6b0";
}
.#{$linearico-css-prefix}-printer:before {
	content: "\e6b1";
}
.#{$linearico-css-prefix}-shredder:before {
	content: "\e6b2";
}
.#{$linearico-css-prefix}-file-empty:before {
	content: "\e6b3";
}
.#{$linearico-css-prefix}-file-add:before {
	content: "\e6b4";
}
.#{$linearico-css-prefix}-file-check:before {
	content: "\e6b5";
}
.#{$linearico-css-prefix}-file-lock:before {
	content: "\e6b6";
}
.#{$linearico-css-prefix}-files:before {
	content: "\e6b7";
}
.#{$linearico-css-prefix}-copy:before {
	content: "\e6b8";
}
.#{$linearico-css-prefix}-compare:before {
	content: "\e6b9";
}
.#{$linearico-css-prefix}-folder:before {
	content: "\e6ba";
}
.#{$linearico-css-prefix}-folder-search:before {
	content: "\e6bb";
}
.#{$linearico-css-prefix}-folder-plus:before {
	content: "\e6bc";
}
.#{$linearico-css-prefix}-folder-minus:before {
	content: "\e6bd";
}
.#{$linearico-css-prefix}-folder-download:before {
	content: "\e6be";
}
.#{$linearico-css-prefix}-folder-upload:before {
	content: "\e6bf";
}
.#{$linearico-css-prefix}-folder-star:before {
	content: "\e6c0";
}
.#{$linearico-css-prefix}-folder-heart:before {
	content: "\e6c1";
}
.#{$linearico-css-prefix}-folder-user:before {
	content: "\e6c2";
}
.#{$linearico-css-prefix}-folder-shared:before {
	content: "\e6c3";
}
.#{$linearico-css-prefix}-folder-music:before {
	content: "\e6c4";
}
.#{$linearico-css-prefix}-folder-picture:before {
	content: "\e6c5";
}
.#{$linearico-css-prefix}-folder-film:before {
	content: "\e6c6";
}
.#{$linearico-css-prefix}-scissors:before {
	content: "\e6c7";
}
.#{$linearico-css-prefix}-paste:before {
	content: "\e6c8";
}
.#{$linearico-css-prefix}-clipboard-empty:before {
	content: "\e6c9";
}
.#{$linearico-css-prefix}-clipboard-pencil:before {
	content: "\e6ca";
}
.#{$linearico-css-prefix}-clipboard-text:before {
	content: "\e6cb";
}
.#{$linearico-css-prefix}-clipboard-check:before {
	content: "\e6cc";
}
.#{$linearico-css-prefix}-clipboard-down:before {
	content: "\e6cd";
}
.#{$linearico-css-prefix}-clipboard-left:before {
	content: "\e6ce";
}
.#{$linearico-css-prefix}-clipboard-alert:before {
	content: "\e6cf";
}
.#{$linearico-css-prefix}-clipboard-user:before {
	content: "\e6d0";
}
.#{$linearico-css-prefix}-register:before {
	content: "\e6d1";
}
.#{$linearico-css-prefix}-enter:before {
	content: "\e6d2";
}
.#{$linearico-css-prefix}-exit:before {
	content: "\e6d3";
}
.#{$linearico-css-prefix}-papers:before {
	content: "\e6d4";
}
.#{$linearico-css-prefix}-news:before {
	content: "\e6d5";
}
.#{$linearico-css-prefix}-reading:before {
	content: "\e6d6";
}
.#{$linearico-css-prefix}-typewriter:before {
	content: "\e6d7";
}
.#{$linearico-css-prefix}-document:before {
	content: "\e6d8";
}
.#{$linearico-css-prefix}-document2:before {
	content: "\e6d9";
}
.#{$linearico-css-prefix}-graduation-hat:before {
	content: "\e6da";
}
.#{$linearico-css-prefix}-license:before {
	content: "\e6db";
}
.#{$linearico-css-prefix}-license2:before {
	content: "\e6dc";
}
.#{$linearico-css-prefix}-medal-empty:before {
	content: "\e6dd";
}
.#{$linearico-css-prefix}-medal-first:before {
	content: "\e6de";
}
.#{$linearico-css-prefix}-medal-second:before {
	content: "\e6df";
}
.#{$linearico-css-prefix}-medal-third:before {
	content: "\e6e0";
}
.#{$linearico-css-prefix}-podium:before {
	content: "\e6e1";
}
.#{$linearico-css-prefix}-trophy:before {
	content: "\e6e2";
}
.#{$linearico-css-prefix}-trophy2:before {
	content: "\e6e3";
}
.#{$linearico-css-prefix}-music-note:before {
	content: "\e6e4";
}
.#{$linearico-css-prefix}-music-note2:before {
	content: "\e6e5";
}
.#{$linearico-css-prefix}-music-note3:before {
	content: "\e6e6";
}
.#{$linearico-css-prefix}-playlist:before {
	content: "\e6e7";
}
.#{$linearico-css-prefix}-playlist-add:before {
	content: "\e6e8";
}
.#{$linearico-css-prefix}-guitar:before {
	content: "\e6e9";
}
.#{$linearico-css-prefix}-trumpet:before {
	content: "\e6ea";
}
.#{$linearico-css-prefix}-album:before {
	content: "\e6eb";
}
.#{$linearico-css-prefix}-shuffle:before {
	content: "\e6ec";
}
.#{$linearico-css-prefix}-repeat-one:before {
	content: "\e6ed";
}
.#{$linearico-css-prefix}-repeat:before {
	content: "\e6ee";
}
.#{$linearico-css-prefix}-headphones:before {
	content: "\e6ef";
}
.#{$linearico-css-prefix}-headset:before {
	content: "\e6f0";
}
.#{$linearico-css-prefix}-loudspeaker:before {
	content: "\e6f1";
}
.#{$linearico-css-prefix}-equalizer:before {
	content: "\e6f2";
}
.#{$linearico-css-prefix}-theater:before {
	content: "\e6f3";
}
.#{$linearico-css-prefix}-3d-glasses:before {
	content: "\e6f4";
}
.#{$linearico-css-prefix}-ticket:before {
	content: "\e6f5";
}
.#{$linearico-css-prefix}-presentation:before {
	content: "\e6f6";
}
.#{$linearico-css-prefix}-play:before {
	content: "\e6f7";
}
.#{$linearico-css-prefix}-film-play:before {
	content: "\e6f8";
}
.#{$linearico-css-prefix}-clapboard-play:before {
	content: "\e6f9";
}
.#{$linearico-css-prefix}-media:before {
	content: "\e6fa";
}
.#{$linearico-css-prefix}-film:before {
	content: "\e6fb";
}
.#{$linearico-css-prefix}-film2:before {
	content: "\e6fc";
}
.#{$linearico-css-prefix}-surveillance:before {
	content: "\e6fd";
}
.#{$linearico-css-prefix}-surveillance2:before {
	content: "\e6fe";
}
.#{$linearico-css-prefix}-camera:before {
	content: "\e6ff";
}
.#{$linearico-css-prefix}-camera-crossed:before {
	content: "\e700";
}
.#{$linearico-css-prefix}-camera-play:before {
	content: "\e701";
}
.#{$linearico-css-prefix}-time-lapse:before {
	content: "\e702";
}
.#{$linearico-css-prefix}-record:before {
	content: "\e703";
}
.#{$linearico-css-prefix}-camera2:before {
	content: "\e704";
}
.#{$linearico-css-prefix}-camera-flip:before {
	content: "\e705";
}
.#{$linearico-css-prefix}-panorama:before {
	content: "\e706";
}
.#{$linearico-css-prefix}-time-lapse2:before {
	content: "\e707";
}
.#{$linearico-css-prefix}-shutter:before {
	content: "\e708";
}
.#{$linearico-css-prefix}-shutter2:before {
	content: "\e709";
}
.#{$linearico-css-prefix}-face-detection:before {
	content: "\e70a";
}
.#{$linearico-css-prefix}-flare:before {
	content: "\e70b";
}
.#{$linearico-css-prefix}-convex:before {
	content: "\e70c";
}
.#{$linearico-css-prefix}-concave:before {
	content: "\e70d";
}
.#{$linearico-css-prefix}-picture:before {
	content: "\e70e";
}
.#{$linearico-css-prefix}-picture2:before {
	content: "\e70f";
}
.#{$linearico-css-prefix}-picture3:before {
	content: "\e710";
}
.#{$linearico-css-prefix}-pictures:before {
	content: "\e711";
}
.#{$linearico-css-prefix}-book:before {
	content: "\e712";
}
.#{$linearico-css-prefix}-audio-book:before {
	content: "\e713";
}
.#{$linearico-css-prefix}-book2:before {
	content: "\e714";
}
.#{$linearico-css-prefix}-bookmark:before {
	content: "\e715";
}
.#{$linearico-css-prefix}-bookmark2:before {
	content: "\e716";
}
.#{$linearico-css-prefix}-label:before {
	content: "\e717";
}
.#{$linearico-css-prefix}-library:before {
	content: "\e718";
}
.#{$linearico-css-prefix}-library2:before {
	content: "\e719";
}
.#{$linearico-css-prefix}-contacts:before {
	content: "\e71a";
}
.#{$linearico-css-prefix}-profile:before {
	content: "\e71b";
}
.#{$linearico-css-prefix}-portrait:before {
	content: "\e71c";
}
.#{$linearico-css-prefix}-portrait2:before {
	content: "\e71d";
}
.#{$linearico-css-prefix}-user:before {
	content: "\e71e";
}
.#{$linearico-css-prefix}-user-plus:before {
	content: "\e71f";
}
.#{$linearico-css-prefix}-user-minus:before {
	content: "\e720";
}
.#{$linearico-css-prefix}-user-lock:before {
	content: "\e721";
}
.#{$linearico-css-prefix}-users:before {
	content: "\e722";
}
.#{$linearico-css-prefix}-users2:before {
	content: "\e723";
}
.#{$linearico-css-prefix}-users-plus:before {
	content: "\e724";
}
.#{$linearico-css-prefix}-users-minus:before {
	content: "\e725";
}
.#{$linearico-css-prefix}-group-work:before {
	content: "\e726";
}
.#{$linearico-css-prefix}-woman:before {
	content: "\e727";
}
.#{$linearico-css-prefix}-man:before {
	content: "\e728";
}
.#{$linearico-css-prefix}-baby:before {
	content: "\e729";
}
.#{$linearico-css-prefix}-baby2:before {
	content: "\e72a";
}
.#{$linearico-css-prefix}-baby3:before {
	content: "\e72b";
}
.#{$linearico-css-prefix}-baby-bottle:before {
	content: "\e72c";
}
.#{$linearico-css-prefix}-walk:before {
	content: "\e72d";
}
.#{$linearico-css-prefix}-hand-waving:before {
	content: "\e72e";
}
.#{$linearico-css-prefix}-jump:before {
	content: "\e72f";
}
.#{$linearico-css-prefix}-run:before {
	content: "\e730";
}
.#{$linearico-css-prefix}-woman2:before {
	content: "\e731";
}
.#{$linearico-css-prefix}-man2:before {
	content: "\e732";
}
.#{$linearico-css-prefix}-man-woman:before {
	content: "\e733";
}
.#{$linearico-css-prefix}-height:before {
	content: "\e734";
}
.#{$linearico-css-prefix}-weight:before {
	content: "\e735";
}
.#{$linearico-css-prefix}-scale:before {
	content: "\e736";
}
.#{$linearico-css-prefix}-button:before {
	content: "\e737";
}
.#{$linearico-css-prefix}-bow-tie:before {
	content: "\e738";
}
.#{$linearico-css-prefix}-tie:before {
	content: "\e739";
}
.#{$linearico-css-prefix}-socks:before {
	content: "\e73a";
}
.#{$linearico-css-prefix}-shoe:before {
	content: "\e73b";
}
.#{$linearico-css-prefix}-shoes:before {
	content: "\e73c";
}
.#{$linearico-css-prefix}-hat:before {
	content: "\e73d";
}
.#{$linearico-css-prefix}-pants:before {
	content: "\e73e";
}
.#{$linearico-css-prefix}-shorts:before {
	content: "\e73f";
}
.#{$linearico-css-prefix}-flip-flops:before {
	content: "\e740";
}
.#{$linearico-css-prefix}-shirt:before {
	content: "\e741";
}
.#{$linearico-css-prefix}-hanger:before {
	content: "\e742";
}
.#{$linearico-css-prefix}-laundry:before {
	content: "\e743";
}
.#{$linearico-css-prefix}-store:before {
	content: "\e744";
}
.#{$linearico-css-prefix}-haircut:before {
	content: "\e745";
}
.#{$linearico-css-prefix}-store-24:before {
	content: "\e746";
}
.#{$linearico-css-prefix}-barcode:before {
	content: "\e747";
}
.#{$linearico-css-prefix}-barcode2:before {
	content: "\e748";
}
.#{$linearico-css-prefix}-barcode3:before {
	content: "\e749";
}
.#{$linearico-css-prefix}-cashier:before {
	content: "\e74a";
}
.#{$linearico-css-prefix}-bag:before {
	content: "\e74b";
}
.#{$linearico-css-prefix}-bag2:before {
	content: "\e74c";
}
.#{$linearico-css-prefix}-cart:before {
	content: "\e74d";
}
.#{$linearico-css-prefix}-cart-empty:before {
	content: "\e74e";
}
.#{$linearico-css-prefix}-cart-full:before {
	content: "\e74f";
}
.#{$linearico-css-prefix}-cart-plus:before {
	content: "\e750";
}
.#{$linearico-css-prefix}-cart-plus2:before {
	content: "\e751";
}
.#{$linearico-css-prefix}-cart-add:before {
	content: "\e752";
}
.#{$linearico-css-prefix}-cart-remove:before {
	content: "\e753";
}
.#{$linearico-css-prefix}-cart-exchange:before {
	content: "\e754";
}
.#{$linearico-css-prefix}-tag:before {
	content: "\e755";
}
.#{$linearico-css-prefix}-tags:before {
	content: "\e756";
}
.#{$linearico-css-prefix}-receipt:before {
	content: "\e757";
}
.#{$linearico-css-prefix}-wallet:before {
	content: "\e758";
}
.#{$linearico-css-prefix}-credit-card:before {
	content: "\e759";
}
.#{$linearico-css-prefix}-cash-dollar:before {
	content: "\e75a";
}
.#{$linearico-css-prefix}-cash-euro:before {
	content: "\e75b";
}
.#{$linearico-css-prefix}-cash-pound:before {
	content: "\e75c";
}
.#{$linearico-css-prefix}-cash-yen:before {
	content: "\e75d";
}
.#{$linearico-css-prefix}-bag-dollar:before {
	content: "\e75e";
}
.#{$linearico-css-prefix}-bag-euro:before {
	content: "\e75f";
}
.#{$linearico-css-prefix}-bag-pound:before {
	content: "\e760";
}
.#{$linearico-css-prefix}-bag-yen:before {
	content: "\e761";
}
.#{$linearico-css-prefix}-coin-dollar:before {
	content: "\e762";
}
.#{$linearico-css-prefix}-coin-euro:before {
	content: "\e763";
}
.#{$linearico-css-prefix}-coin-pound:before {
	content: "\e764";
}
.#{$linearico-css-prefix}-coin-yen:before {
	content: "\e765";
}
.#{$linearico-css-prefix}-calculator:before {
	content: "\e766";
}
.#{$linearico-css-prefix}-calculator2:before {
	content: "\e767";
}
.#{$linearico-css-prefix}-abacus:before {
	content: "\e768";
}
.#{$linearico-css-prefix}-vault:before {
	content: "\e769";
}
.#{$linearico-css-prefix}-telephone:before {
	content: "\e76a";
}
.#{$linearico-css-prefix}-phone-lock:before {
	content: "\e76b";
}
.#{$linearico-css-prefix}-phone-wave:before {
	content: "\e76c";
}
.#{$linearico-css-prefix}-phone-pause:before {
	content: "\e76d";
}
.#{$linearico-css-prefix}-phone-outgoing:before {
	content: "\e76e";
}
.#{$linearico-css-prefix}-phone-incoming:before {
	content: "\e76f";
}
.#{$linearico-css-prefix}-phone-in-out:before {
	content: "\e770";
}
.#{$linearico-css-prefix}-phone-error:before {
	content: "\e771";
}
.#{$linearico-css-prefix}-phone-sip:before {
	content: "\e772";
}
.#{$linearico-css-prefix}-phone-plus:before {
	content: "\e773";
}
.#{$linearico-css-prefix}-phone-minus:before {
	content: "\e774";
}
.#{$linearico-css-prefix}-voicemail:before {
	content: "\e775";
}
.#{$linearico-css-prefix}-dial:before {
	content: "\e776";
}
.#{$linearico-css-prefix}-telephone2:before {
	content: "\e777";
}
.#{$linearico-css-prefix}-pushpin:before {
	content: "\e778";
}
.#{$linearico-css-prefix}-pushpin2:before {
	content: "\e779";
}
.#{$linearico-css-prefix}-map-marker:before {
	content: "\e77a";
}
.#{$linearico-css-prefix}-map-marker-user:before {
	content: "\e77b";
}
.#{$linearico-css-prefix}-map-marker-down:before {
	content: "\e77c";
}
.#{$linearico-css-prefix}-map-marker-check:before {
	content: "\e77d";
}
.#{$linearico-css-prefix}-map-marker-crossed:before {
	content: "\e77e";
}
.#{$linearico-css-prefix}-radar:before {
	content: "\e77f";
}
.#{$linearico-css-prefix}-compass2:before {
	content: "\e780";
}
.#{$linearico-css-prefix}-map:before {
	content: "\e781";
}
.#{$linearico-css-prefix}-map2:before {
	content: "\e782";
}
.#{$linearico-css-prefix}-location:before {
	content: "\e783";
}
.#{$linearico-css-prefix}-road-sign:before {
	content: "\e784";
}
.#{$linearico-css-prefix}-calendar-empty:before {
	content: "\e785";
}
.#{$linearico-css-prefix}-calendar-check:before {
	content: "\e786";
}
.#{$linearico-css-prefix}-calendar-cross:before {
	content: "\e787";
}
.#{$linearico-css-prefix}-calendar-31:before {
	content: "\e788";
}
.#{$linearico-css-prefix}-calendar-full:before {
	content: "\e789";
}
.#{$linearico-css-prefix}-calendar-insert:before {
	content: "\e78a";
}
.#{$linearico-css-prefix}-calendar-text:before {
	content: "\e78b";
}
.#{$linearico-css-prefix}-calendar-user:before {
	content: "\e78c";
}
.#{$linearico-css-prefix}-mouse:before {
	content: "\e78d";
}
.#{$linearico-css-prefix}-mouse-left:before {
	content: "\e78e";
}
.#{$linearico-css-prefix}-mouse-right:before {
	content: "\e78f";
}
.#{$linearico-css-prefix}-mouse-both:before {
	content: "\e790";
}
.#{$linearico-css-prefix}-keyboard:before {
	content: "\e791";
}
.#{$linearico-css-prefix}-keyboard-up:before {
	content: "\e792";
}
.#{$linearico-css-prefix}-keyboard-down:before {
	content: "\e793";
}
.#{$linearico-css-prefix}-delete:before {
	content: "\e794";
}
.#{$linearico-css-prefix}-spell-check:before {
	content: "\e795";
}
.#{$linearico-css-prefix}-escape:before {
	content: "\e796";
}
.#{$linearico-css-prefix}-enter2:before {
	content: "\e797";
}
.#{$linearico-css-prefix}-screen:before {
	content: "\e798";
}
.#{$linearico-css-prefix}-aspect-ratio:before {
	content: "\e799";
}
.#{$linearico-css-prefix}-signal:before {
	content: "\e79a";
}
.#{$linearico-css-prefix}-signal-lock:before {
	content: "\e79b";
}
.#{$linearico-css-prefix}-signal-80:before {
	content: "\e79c";
}
.#{$linearico-css-prefix}-signal-60:before {
	content: "\e79d";
}
.#{$linearico-css-prefix}-signal-40:before {
	content: "\e79e";
}
.#{$linearico-css-prefix}-signal-20:before {
	content: "\e79f";
}
.#{$linearico-css-prefix}-signal-0:before {
	content: "\e7a0";
}
.#{$linearico-css-prefix}-signal-blocked:before {
	content: "\e7a1";
}
.#{$linearico-css-prefix}-sim:before {
	content: "\e7a2";
}
.#{$linearico-css-prefix}-flash-memory:before {
	content: "\e7a3";
}
.#{$linearico-css-prefix}-usb-drive:before {
	content: "\e7a4";
}
.#{$linearico-css-prefix}-phone:before {
	content: "\e7a5";
}
.#{$linearico-css-prefix}-smartphone:before {
	content: "\e7a6";
}
.#{$linearico-css-prefix}-smartphone-notification:before {
	content: "\e7a7";
}
.#{$linearico-css-prefix}-smartphone-vibration:before {
	content: "\e7a8";
}
.#{$linearico-css-prefix}-smartphone-embed:before {
	content: "\e7a9";
}
.#{$linearico-css-prefix}-smartphone-waves:before {
	content: "\e7aa";
}
.#{$linearico-css-prefix}-tablet:before {
	content: "\e7ab";
}
.#{$linearico-css-prefix}-tablet2:before {
	content: "\e7ac";
}
.#{$linearico-css-prefix}-laptop:before {
	content: "\e7ad";
}
.#{$linearico-css-prefix}-laptop-phone:before {
	content: "\e7ae";
}
.#{$linearico-css-prefix}-desktop:before {
	content: "\e7af";
}
.#{$linearico-css-prefix}-launch:before {
	content: "\e7b0";
}
.#{$linearico-css-prefix}-new-tab:before {
	content: "\e7b1";
}
.#{$linearico-css-prefix}-window:before {
	content: "\e7b2";
}
.#{$linearico-css-prefix}-cable:before {
	content: "\e7b3";
}
.#{$linearico-css-prefix}-cable2:before {
	content: "\e7b4";
}
.#{$linearico-css-prefix}-tv:before {
	content: "\e7b5";
}
.#{$linearico-css-prefix}-radio:before {
	content: "\e7b6";
}
.#{$linearico-css-prefix}-remote-control:before {
	content: "\e7b7";
}
.#{$linearico-css-prefix}-power-switch:before {
	content: "\e7b8";
}
.#{$linearico-css-prefix}-power:before {
	content: "\e7b9";
}
.#{$linearico-css-prefix}-power-crossed:before {
	content: "\e7ba";
}
.#{$linearico-css-prefix}-flash-auto:before {
	content: "\e7bb";
}
.#{$linearico-css-prefix}-lamp:before {
	content: "\e7bc";
}
.#{$linearico-css-prefix}-flashlight:before {
	content: "\e7bd";
}
.#{$linearico-css-prefix}-lampshade:before {
	content: "\e7be";
}
.#{$linearico-css-prefix}-cord:before {
	content: "\e7bf";
}
.#{$linearico-css-prefix}-outlet:before {
	content: "\e7c0";
}
.#{$linearico-css-prefix}-battery-power:before {
	content: "\e7c1";
}
.#{$linearico-css-prefix}-battery-empty:before {
	content: "\e7c2";
}
.#{$linearico-css-prefix}-battery-alert:before {
	content: "\e7c3";
}
.#{$linearico-css-prefix}-battery-error:before {
	content: "\e7c4";
}
.#{$linearico-css-prefix}-battery-low1:before {
	content: "\e7c5";
}
.#{$linearico-css-prefix}-battery-low2:before {
	content: "\e7c6";
}
.#{$linearico-css-prefix}-battery-low3:before {
	content: "\e7c7";
}
.#{$linearico-css-prefix}-battery-mid1:before {
	content: "\e7c8";
}
.#{$linearico-css-prefix}-battery-mid2:before {
	content: "\e7c9";
}
.#{$linearico-css-prefix}-battery-mid3:before {
	content: "\e7ca";
}
.#{$linearico-css-prefix}-battery-full:before {
	content: "\e7cb";
}
.#{$linearico-css-prefix}-battery-charging:before {
	content: "\e7cc";
}
.#{$linearico-css-prefix}-battery-charging2:before {
	content: "\e7cd";
}
.#{$linearico-css-prefix}-battery-charging3:before {
	content: "\e7ce";
}
.#{$linearico-css-prefix}-battery-charging4:before {
	content: "\e7cf";
}
.#{$linearico-css-prefix}-battery-charging5:before {
	content: "\e7d0";
}
.#{$linearico-css-prefix}-battery-charging6:before {
	content: "\e7d1";
}
.#{$linearico-css-prefix}-battery-charging7:before {
	content: "\e7d2";
}
.#{$linearico-css-prefix}-chip:before {
	content: "\e7d3";
}
.#{$linearico-css-prefix}-chip-x64:before {
	content: "\e7d4";
}
.#{$linearico-css-prefix}-chip-x86:before {
	content: "\e7d5";
}
.#{$linearico-css-prefix}-bubble:before {
	content: "\e7d6";
}
.#{$linearico-css-prefix}-bubbles:before {
	content: "\e7d7";
}
.#{$linearico-css-prefix}-bubble-dots:before {
	content: "\e7d8";
}
.#{$linearico-css-prefix}-bubble-alert:before {
	content: "\e7d9";
}
.#{$linearico-css-prefix}-bubble-question:before {
	content: "\e7da";
}
.#{$linearico-css-prefix}-bubble-text:before {
	content: "\e7db";
}
.#{$linearico-css-prefix}-bubble-pencil:before {
	content: "\e7dc";
}
.#{$linearico-css-prefix}-bubble-picture:before {
	content: "\e7dd";
}
.#{$linearico-css-prefix}-bubble-video:before {
	content: "\e7de";
}
.#{$linearico-css-prefix}-bubble-user:before {
	content: "\e7df";
}
.#{$linearico-css-prefix}-bubble-quote:before {
	content: "\e7e0";
}
.#{$linearico-css-prefix}-bubble-heart:before {
	content: "\e7e1";
}
.#{$linearico-css-prefix}-bubble-emoticon:before {
	content: "\e7e2";
}
.#{$linearico-css-prefix}-bubble-attachment:before {
	content: "\e7e3";
}
.#{$linearico-css-prefix}-phone-bubble:before {
	content: "\e7e4";
}
.#{$linearico-css-prefix}-quote-open:before {
	content: "\e7e5";
}
.#{$linearico-css-prefix}-quote-close:before {
	content: "\e7e6";
}
.#{$linearico-css-prefix}-dna:before {
	content: "\e7e7";
}
.#{$linearico-css-prefix}-heart-pulse:before {
	content: "\e7e8";
}
.#{$linearico-css-prefix}-pulse:before {
	content: "\e7e9";
}
.#{$linearico-css-prefix}-syringe:before {
	content: "\e7ea";
}
.#{$linearico-css-prefix}-pills:before {
	content: "\e7eb";
}
.#{$linearico-css-prefix}-first-aid:before {
	content: "\e7ec";
}
.#{$linearico-css-prefix}-lifebuoy:before {
	content: "\e7ed";
}
.#{$linearico-css-prefix}-bandage:before {
	content: "\e7ee";
}
.#{$linearico-css-prefix}-bandages:before {
	content: "\e7ef";
}
.#{$linearico-css-prefix}-thermometer:before {
	content: "\e7f0";
}
.#{$linearico-css-prefix}-microscope:before {
	content: "\e7f1";
}
.#{$linearico-css-prefix}-brain:before {
	content: "\e7f2";
}
.#{$linearico-css-prefix}-beaker:before {
	content: "\e7f3";
}
.#{$linearico-css-prefix}-skull:before {
	content: "\e7f4";
}
.#{$linearico-css-prefix}-bone:before {
	content: "\e7f5";
}
.#{$linearico-css-prefix}-construction:before {
	content: "\e7f6";
}
.#{$linearico-css-prefix}-construction-cone:before {
	content: "\e7f7";
}
.#{$linearico-css-prefix}-pie-chart:before {
	content: "\e7f8";
}
.#{$linearico-css-prefix}-pie-chart2:before {
	content: "\e7f9";
}
.#{$linearico-css-prefix}-graph:before {
	content: "\e7fa";
}
.#{$linearico-css-prefix}-chart-growth:before {
	content: "\e7fb";
}
.#{$linearico-css-prefix}-chart-bars:before {
	content: "\e7fc";
}
.#{$linearico-css-prefix}-chart-settings:before {
	content: "\e7fd";
}
.#{$linearico-css-prefix}-cake:before {
	content: "\e7fe";
}
.#{$linearico-css-prefix}-gift:before {
	content: "\e7ff";
}
.#{$linearico-css-prefix}-balloon:before {
	content: "\e800";
}
.#{$linearico-css-prefix}-rank:before {
	content: "\e801";
}
.#{$linearico-css-prefix}-rank2:before {
	content: "\e802";
}
.#{$linearico-css-prefix}-rank3:before {
	content: "\e803";
}
.#{$linearico-css-prefix}-crown:before {
	content: "\e804";
}
.#{$linearico-css-prefix}-lotus:before {
	content: "\e805";
}
.#{$linearico-css-prefix}-diamond:before {
	content: "\e806";
}
.#{$linearico-css-prefix}-diamond2:before {
	content: "\e807";
}
.#{$linearico-css-prefix}-diamond3:before {
	content: "\e808";
}
.#{$linearico-css-prefix}-diamond4:before {
	content: "\e809";
}
.#{$linearico-css-prefix}-linearicons:before {
	content: "\e80a";
}
.#{$linearico-css-prefix}-teacup:before {
	content: "\e80b";
}
.#{$linearico-css-prefix}-teapot:before {
	content: "\e80c";
}
.#{$linearico-css-prefix}-glass:before {
	content: "\e80d";
}
.#{$linearico-css-prefix}-bottle2:before {
	content: "\e80e";
}
.#{$linearico-css-prefix}-glass-cocktail:before {
	content: "\e80f";
}
.#{$linearico-css-prefix}-glass2:before {
	content: "\e810";
}
.#{$linearico-css-prefix}-dinner:before {
	content: "\e811";
}
.#{$linearico-css-prefix}-dinner2:before {
	content: "\e812";
}
.#{$linearico-css-prefix}-chef:before {
	content: "\e813";
}
.#{$linearico-css-prefix}-scale2:before {
	content: "\e814";
}
.#{$linearico-css-prefix}-egg:before {
	content: "\e815";
}
.#{$linearico-css-prefix}-egg2:before {
	content: "\e816";
}
.#{$linearico-css-prefix}-eggs:before {
	content: "\e817";
}
.#{$linearico-css-prefix}-platter:before {
	content: "\e818";
}
.#{$linearico-css-prefix}-steak:before {
	content: "\e819";
}
.#{$linearico-css-prefix}-hamburger:before {
	content: "\e81a";
}
.#{$linearico-css-prefix}-hotdog:before {
	content: "\e81b";
}
.#{$linearico-css-prefix}-pizza:before {
	content: "\e81c";
}
.#{$linearico-css-prefix}-sausage:before {
	content: "\e81d";
}
.#{$linearico-css-prefix}-chicken:before {
	content: "\e81e";
}
.#{$linearico-css-prefix}-fish:before {
	content: "\e81f";
}
.#{$linearico-css-prefix}-carrot:before {
	content: "\e820";
}
.#{$linearico-css-prefix}-cheese:before {
	content: "\e821";
}
.#{$linearico-css-prefix}-bread:before {
	content: "\e822";
}
.#{$linearico-css-prefix}-ice-cream:before {
	content: "\e823";
}
.#{$linearico-css-prefix}-ice-cream2:before {
	content: "\e824";
}
.#{$linearico-css-prefix}-candy:before {
	content: "\e825";
}
.#{$linearico-css-prefix}-lollipop:before {
	content: "\e826";
}
.#{$linearico-css-prefix}-coffee-bean:before {
	content: "\e827";
}
.#{$linearico-css-prefix}-coffee-cup:before {
	content: "\e828";
}
.#{$linearico-css-prefix}-cherry:before {
	content: "\e829";
}
.#{$linearico-css-prefix}-grapes:before {
	content: "\e82a";
}
.#{$linearico-css-prefix}-citrus:before {
	content: "\e82b";
}
.#{$linearico-css-prefix}-apple:before {
	content: "\e82c";
}
.#{$linearico-css-prefix}-leaf:before {
	content: "\e82d";
}
.#{$linearico-css-prefix}-landscape:before {
	content: "\e82e";
}
.#{$linearico-css-prefix}-pine-tree:before {
	content: "\e82f";
}
.#{$linearico-css-prefix}-tree:before {
	content: "\e830";
}
.#{$linearico-css-prefix}-cactus:before {
	content: "\e831";
}
.#{$linearico-css-prefix}-paw:before {
	content: "\e832";
}
.#{$linearico-css-prefix}-footprint:before {
	content: "\e833";
}
.#{$linearico-css-prefix}-speed-slow:before {
	content: "\e834";
}
.#{$linearico-css-prefix}-speed-medium:before {
	content: "\e835";
}
.#{$linearico-css-prefix}-speed-fast:before {
	content: "\e836";
}
.#{$linearico-css-prefix}-rocket:before {
	content: "\e837";
}
.#{$linearico-css-prefix}-hammer2:before {
	content: "\e838";
}
.#{$linearico-css-prefix}-balance:before {
	content: "\e839";
}
.#{$linearico-css-prefix}-briefcase:before {
	content: "\e83a";
}
.#{$linearico-css-prefix}-luggage-weight:before {
	content: "\e83b";
}
.#{$linearico-css-prefix}-dolly:before {
	content: "\e83c";
}
.#{$linearico-css-prefix}-plane:before {
	content: "\e83d";
}
.#{$linearico-css-prefix}-plane-crossed:before {
	content: "\e83e";
}
.#{$linearico-css-prefix}-helicopter:before {
	content: "\e83f";
}
.#{$linearico-css-prefix}-traffic-lights:before {
	content: "\e840";
}
.#{$linearico-css-prefix}-siren:before {
	content: "\e841";
}
.#{$linearico-css-prefix}-road:before {
	content: "\e842";
}
.#{$linearico-css-prefix}-engine:before {
	content: "\e843";
}
.#{$linearico-css-prefix}-oil-pressure:before {
	content: "\e844";
}
.#{$linearico-css-prefix}-coolant-temperature:before {
	content: "\e845";
}
.#{$linearico-css-prefix}-car-battery:before {
	content: "\e846";
}
.#{$linearico-css-prefix}-gas:before {
	content: "\e847";
}
.#{$linearico-css-prefix}-gallon:before {
	content: "\e848";
}
.#{$linearico-css-prefix}-transmission:before {
	content: "\e849";
}
.#{$linearico-css-prefix}-car:before {
	content: "\e84a";
}
.#{$linearico-css-prefix}-car-wash:before {
	content: "\e84b";
}
.#{$linearico-css-prefix}-car-wash2:before {
	content: "\e84c";
}
.#{$linearico-css-prefix}-bus:before {
	content: "\e84d";
}
.#{$linearico-css-prefix}-bus2:before {
	content: "\e84e";
}
.#{$linearico-css-prefix}-car2:before {
	content: "\e84f";
}
.#{$linearico-css-prefix}-parking:before {
	content: "\e850";
}
.#{$linearico-css-prefix}-car-lock:before {
	content: "\e851";
}
.#{$linearico-css-prefix}-taxi:before {
	content: "\e852";
}
.#{$linearico-css-prefix}-car-siren:before {
	content: "\e853";
}
.#{$linearico-css-prefix}-car-wash3:before {
	content: "\e854";
}
.#{$linearico-css-prefix}-car-wash4:before {
	content: "\e855";
}
.#{$linearico-css-prefix}-ambulance:before {
	content: "\e856";
}
.#{$linearico-css-prefix}-truck:before {
	content: "\e857";
}
.#{$linearico-css-prefix}-trailer:before {
	content: "\e858";
}
.#{$linearico-css-prefix}-scale-truck:before {
	content: "\e859";
}
.#{$linearico-css-prefix}-train:before {
	content: "\e85a";
}
.#{$linearico-css-prefix}-ship:before {
	content: "\e85b";
}
.#{$linearico-css-prefix}-ship2:before {
	content: "\e85c";
}
.#{$linearico-css-prefix}-anchor:before {
	content: "\e85d";
}
.#{$linearico-css-prefix}-boat:before {
	content: "\e85e";
}
.#{$linearico-css-prefix}-bicycle:before {
	content: "\e85f";
}
.#{$linearico-css-prefix}-bicycle2:before {
	content: "\e860";
}
.#{$linearico-css-prefix}-dumbbell:before {
	content: "\e861";
}
.#{$linearico-css-prefix}-bench-press:before {
	content: "\e862";
}
.#{$linearico-css-prefix}-swim:before {
	content: "\e863";
}
.#{$linearico-css-prefix}-football:before {
	content: "\e864";
}
.#{$linearico-css-prefix}-baseball-bat:before {
	content: "\e865";
}
.#{$linearico-css-prefix}-baseball:before {
	content: "\e866";
}
.#{$linearico-css-prefix}-tennis:before {
	content: "\e867";
}
.#{$linearico-css-prefix}-tennis2:before {
	content: "\e868";
}
.#{$linearico-css-prefix}-ping-pong:before {
	content: "\e869";
}
.#{$linearico-css-prefix}-hockey:before {
	content: "\e86a";
}
.#{$linearico-css-prefix}-8ball:before {
	content: "\e86b";
}
.#{$linearico-css-prefix}-bowling:before {
	content: "\e86c";
}
.#{$linearico-css-prefix}-bowling-pins:before {
	content: "\e86d";
}
.#{$linearico-css-prefix}-golf:before {
	content: "\e86e";
}
.#{$linearico-css-prefix}-golf2:before {
	content: "\e86f";
}
.#{$linearico-css-prefix}-archery:before {
	content: "\e870";
}
.#{$linearico-css-prefix}-slingshot:before {
	content: "\e871";
}
.#{$linearico-css-prefix}-soccer:before {
	content: "\e872";
}
.#{$linearico-css-prefix}-basketball:before {
	content: "\e873";
}
.#{$linearico-css-prefix}-cube:before {
	content: "\e874";
}
.#{$linearico-css-prefix}-3d-rotate:before {
	content: "\e875";
}
.#{$linearico-css-prefix}-puzzle:before {
	content: "\e876";
}
.#{$linearico-css-prefix}-glasses:before {
	content: "\e877";
}
.#{$linearico-css-prefix}-glasses2:before {
	content: "\e878";
}
.#{$linearico-css-prefix}-accessibility:before {
	content: "\e879";
}
.#{$linearico-css-prefix}-wheelchair:before {
	content: "\e87a";
}
.#{$linearico-css-prefix}-wall:before {
	content: "\e87b";
}
.#{$linearico-css-prefix}-fence:before {
	content: "\e87c";
}
.#{$linearico-css-prefix}-wall2:before {
	content: "\e87d";
}
.#{$linearico-css-prefix}-icons:before {
	content: "\e87e";
}
.#{$linearico-css-prefix}-resize-handle:before {
	content: "\e87f";
}
.#{$linearico-css-prefix}-icons2:before {
	content: "\e880";
}
.#{$linearico-css-prefix}-select:before {
	content: "\e881";
}
.#{$linearico-css-prefix}-select2:before {
	content: "\e882";
}
.#{$linearico-css-prefix}-site-map:before {
	content: "\e883";
}
.#{$linearico-css-prefix}-earth:before {
	content: "\e884";
}
.#{$linearico-css-prefix}-earth-lock:before {
	content: "\e885";
}
.#{$linearico-css-prefix}-network:before {
	content: "\e886";
}
.#{$linearico-css-prefix}-network-lock:before {
	content: "\e887";
}
.#{$linearico-css-prefix}-planet:before {
	content: "\e888";
}
.#{$linearico-css-prefix}-happy:before {
	content: "\e889";
}
.#{$linearico-css-prefix}-smile:before {
	content: "\e88a";
}
.#{$linearico-css-prefix}-grin:before {
	content: "\e88b";
}
.#{$linearico-css-prefix}-tongue:before {
	content: "\e88c";
}
.#{$linearico-css-prefix}-sad:before {
	content: "\e88d";
}
.#{$linearico-css-prefix}-wink:before {
	content: "\e88e";
}
.#{$linearico-css-prefix}-dream:before {
	content: "\e88f";
}
.#{$linearico-css-prefix}-shocked:before {
	content: "\e890";
}
.#{$linearico-css-prefix}-shocked2:before {
	content: "\e891";
}
.#{$linearico-css-prefix}-tongue2:before {
	content: "\e892";
}
.#{$linearico-css-prefix}-neutral:before {
	content: "\e893";
}
.#{$linearico-css-prefix}-happy-grin:before {
	content: "\e894";
}
.#{$linearico-css-prefix}-cool:before {
	content: "\e895";
}
.#{$linearico-css-prefix}-mad:before {
	content: "\e896";
}
.#{$linearico-css-prefix}-grin-evil:before {
	content: "\e897";
}
.#{$linearico-css-prefix}-evil:before {
	content: "\e898";
}
.#{$linearico-css-prefix}-wow:before {
	content: "\e899";
}
.#{$linearico-css-prefix}-annoyed:before {
	content: "\e89a";
}
.#{$linearico-css-prefix}-wondering:before {
	content: "\e89b";
}
.#{$linearico-css-prefix}-confused:before {
	content: "\e89c";
}
.#{$linearico-css-prefix}-zipped:before {
	content: "\e89d";
}
.#{$linearico-css-prefix}-grumpy:before {
	content: "\e89e";
}
.#{$linearico-css-prefix}-mustache:before {
	content: "\e89f";
}
.#{$linearico-css-prefix}-tombstone-hipster:before {
	content: "\e8a0";
}
.#{$linearico-css-prefix}-tombstone:before {
	content: "\e8a1";
}
.#{$linearico-css-prefix}-ghost:before {
	content: "\e8a2";
}
.#{$linearico-css-prefix}-ghost-hipster:before {
	content: "\e8a3";
}
.#{$linearico-css-prefix}-halloween:before {
	content: "\e8a4";
}
.#{$linearico-css-prefix}-christmas:before {
	content: "\e8a5";
}
.#{$linearico-css-prefix}-easter-egg:before {
	content: "\e8a6";
}
.#{$linearico-css-prefix}-mustache2:before {
	content: "\e8a7";
}
.#{$linearico-css-prefix}-mustache-glasses:before {
	content: "\e8a8";
}
.#{$linearico-css-prefix}-pipe:before {
	content: "\e8a9";
}
.#{$linearico-css-prefix}-alarm:before {
	content: "\e8aa";
}
.#{$linearico-css-prefix}-alarm-add:before {
	content: "\e8ab";
}
.#{$linearico-css-prefix}-alarm-snooze:before {
	content: "\e8ac";
}
.#{$linearico-css-prefix}-alarm-ringing:before {
	content: "\e8ad";
}
.#{$linearico-css-prefix}-bullhorn:before {
	content: "\e8ae";
}
.#{$linearico-css-prefix}-hearing:before {
	content: "\e8af";
}
.#{$linearico-css-prefix}-volume-high:before {
	content: "\e8b0";
}
.#{$linearico-css-prefix}-volume-medium:before {
	content: "\e8b1";
}
.#{$linearico-css-prefix}-volume-low:before {
	content: "\e8b2";
}
.#{$linearico-css-prefix}-volume:before {
	content: "\e8b3";
}
.#{$linearico-css-prefix}-mute:before {
	content: "\e8b4";
}
.#{$linearico-css-prefix}-lan:before {
	content: "\e8b5";
}
.#{$linearico-css-prefix}-lan2:before {
	content: "\e8b6";
}
.#{$linearico-css-prefix}-wifi:before {
	content: "\e8b7";
}
.#{$linearico-css-prefix}-wifi-lock:before {
	content: "\e8b8";
}
.#{$linearico-css-prefix}-wifi-blocked:before {
	content: "\e8b9";
}
.#{$linearico-css-prefix}-wifi-mid:before {
	content: "\e8ba";
}
.#{$linearico-css-prefix}-wifi-low:before {
	content: "\e8bb";
}
.#{$linearico-css-prefix}-wifi-low2:before {
	content: "\e8bc";
}
.#{$linearico-css-prefix}-wifi-alert:before {
	content: "\e8bd";
}
.#{$linearico-css-prefix}-wifi-alert-mid:before {
	content: "\e8be";
}
.#{$linearico-css-prefix}-wifi-alert-low:before {
	content: "\e8bf";
}
.#{$linearico-css-prefix}-wifi-alert-low2:before {
	content: "\e8c0";
}
.#{$linearico-css-prefix}-stream:before {
	content: "\e8c1";
}
.#{$linearico-css-prefix}-stream-check:before {
	content: "\e8c2";
}
.#{$linearico-css-prefix}-stream-error:before {
	content: "\e8c3";
}
.#{$linearico-css-prefix}-stream-alert:before {
	content: "\e8c4";
}
.#{$linearico-css-prefix}-communication:before {
	content: "\e8c5";
}
.#{$linearico-css-prefix}-communication-crossed:before {
	content: "\e8c6";
}
.#{$linearico-css-prefix}-broadcast:before {
	content: "\e8c7";
}
.#{$linearico-css-prefix}-antenna:before {
	content: "\e8c8";
}
.#{$linearico-css-prefix}-satellite:before {
	content: "\e8c9";
}
.#{$linearico-css-prefix}-satellite2:before {
	content: "\e8ca";
}
.#{$linearico-css-prefix}-mic:before {
	content: "\e8cb";
}
.#{$linearico-css-prefix}-mic-mute:before {
	content: "\e8cc";
}
.#{$linearico-css-prefix}-mic2:before {
	content: "\e8cd";
}
.#{$linearico-css-prefix}-spotlights:before {
	content: "\e8ce";
}
.#{$linearico-css-prefix}-hourglass:before {
	content: "\e8cf";
}
.#{$linearico-css-prefix}-loading:before {
	content: "\e8d0";
}
.#{$linearico-css-prefix}-loading2:before {
	content: "\e8d1";
}
.#{$linearico-css-prefix}-loading3:before {
	content: "\e8d2";
}
.#{$linearico-css-prefix}-refresh:before {
	content: "\e8d3";
}
.#{$linearico-css-prefix}-refresh2:before {
	content: "\e8d4";
}
.#{$linearico-css-prefix}-undo:before {
	content: "\e8d5";
}
.#{$linearico-css-prefix}-redo:before {
	content: "\e8d6";
}
.#{$linearico-css-prefix}-jump2:before {
	content: "\e8d7";
}
.#{$linearico-css-prefix}-undo2:before {
	content: "\e8d8";
}
.#{$linearico-css-prefix}-redo2:before {
	content: "\e8d9";
}
.#{$linearico-css-prefix}-sync:before {
	content: "\e8da";
}
.#{$linearico-css-prefix}-repeat-one2:before {
	content: "\e8db";
}
.#{$linearico-css-prefix}-sync-crossed:before {
	content: "\e8dc";
}
.#{$linearico-css-prefix}-sync2:before {
	content: "\e8dd";
}
.#{$linearico-css-prefix}-repeat-one3:before {
	content: "\e8de";
}
.#{$linearico-css-prefix}-sync-crossed2:before {
	content: "\e8df";
}
.#{$linearico-css-prefix}-return:before {
	content: "\e8e0";
}
.#{$linearico-css-prefix}-return2:before {
	content: "\e8e1";
}
.#{$linearico-css-prefix}-refund:before {
	content: "\e8e2";
}
.#{$linearico-css-prefix}-history:before {
	content: "\e8e3";
}
.#{$linearico-css-prefix}-history2:before {
	content: "\e8e4";
}
.#{$linearico-css-prefix}-self-timer:before {
	content: "\e8e5";
}
.#{$linearico-css-prefix}-clock:before {
	content: "\e8e6";
}
.#{$linearico-css-prefix}-clock2:before {
	content: "\e8e7";
}
.#{$linearico-css-prefix}-clock3:before {
	content: "\e8e8";
}
.#{$linearico-css-prefix}-watch:before {
	content: "\e8e9";
}
.#{$linearico-css-prefix}-alarm2:before {
	content: "\e8ea";
}
.#{$linearico-css-prefix}-alarm-add2:before {
	content: "\e8eb";
}
.#{$linearico-css-prefix}-alarm-remove:before {
	content: "\e8ec";
}
.#{$linearico-css-prefix}-alarm-check:before {
	content: "\e8ed";
}
.#{$linearico-css-prefix}-alarm-error:before {
	content: "\e8ee";
}
.#{$linearico-css-prefix}-timer:before {
	content: "\e8ef";
}
.#{$linearico-css-prefix}-timer-crossed:before {
	content: "\e8f0";
}
.#{$linearico-css-prefix}-timer2:before {
	content: "\e8f1";
}
.#{$linearico-css-prefix}-timer-crossed2:before {
	content: "\e8f2";
}
.#{$linearico-css-prefix}-download:before {
	content: "\e8f3";
}
.#{$linearico-css-prefix}-upload:before {
	content: "\e8f4";
}
.#{$linearico-css-prefix}-download2:before {
	content: "\e8f5";
}
.#{$linearico-css-prefix}-upload2:before {
	content: "\e8f6";
}
.#{$linearico-css-prefix}-enter-up:before {
	content: "\e8f7";
}
.#{$linearico-css-prefix}-enter-down:before {
	content: "\e8f8";
}
.#{$linearico-css-prefix}-enter-left:before {
	content: "\e8f9";
}
.#{$linearico-css-prefix}-enter-right:before {
	content: "\e8fa";
}
.#{$linearico-css-prefix}-exit-up:before {
	content: "\e8fb";
}
.#{$linearico-css-prefix}-exit-down:before {
	content: "\e8fc";
}
.#{$linearico-css-prefix}-exit-left:before {
	content: "\e8fd";
}
.#{$linearico-css-prefix}-exit-right:before {
	content: "\e8fe";
}
.#{$linearico-css-prefix}-enter-up2:before {
	content: "\e8ff";
}
.#{$linearico-css-prefix}-enter-down2:before {
	content: "\e900";
}
.#{$linearico-css-prefix}-enter-vertical:before {
	content: "\e901";
}
.#{$linearico-css-prefix}-enter-left2:before {
	content: "\e902";
}
.#{$linearico-css-prefix}-enter-right2:before {
	content: "\e903";
}
.#{$linearico-css-prefix}-enter-horizontal:before {
	content: "\e904";
}
.#{$linearico-css-prefix}-exit-up2:before {
	content: "\e905";
}
.#{$linearico-css-prefix}-exit-down2:before {
	content: "\e906";
}
.#{$linearico-css-prefix}-exit-left2:before {
	content: "\e907";
}
.#{$linearico-css-prefix}-exit-right2:before {
	content: "\e908";
}
.#{$linearico-css-prefix}-cli:before {
	content: "\e909";
}
.#{$linearico-css-prefix}-bug:before {
	content: "\e90a";
}
.#{$linearico-css-prefix}-code:before {
	content: "\e90b";
}
.#{$linearico-css-prefix}-file-code:before {
	content: "\e90c";
}
.#{$linearico-css-prefix}-file-image:before {
	content: "\e90d";
}
.#{$linearico-css-prefix}-file-zip:before {
	content: "\e90e";
}
.#{$linearico-css-prefix}-file-audio:before {
	content: "\e90f";
}
.#{$linearico-css-prefix}-file-video:before {
	content: "\e910";
}
.#{$linearico-css-prefix}-file-preview:before {
	content: "\e911";
}
.#{$linearico-css-prefix}-file-charts:before {
	content: "\e912";
}
.#{$linearico-css-prefix}-file-stats:before {
	content: "\e913";
}
.#{$linearico-css-prefix}-file-spreadsheet:before {
	content: "\e914";
}
.#{$linearico-css-prefix}-link:before {
	content: "\e915";
}
.#{$linearico-css-prefix}-unlink:before {
	content: "\e916";
}
.#{$linearico-css-prefix}-link2:before {
	content: "\e917";
}
.#{$linearico-css-prefix}-unlink2:before {
	content: "\e918";
}
.#{$linearico-css-prefix}-thumbs-up:before {
	content: "\e919";
}
.#{$linearico-css-prefix}-thumbs-down:before {
	content: "\e91a";
}
.#{$linearico-css-prefix}-thumbs-up2:before {
	content: "\e91b";
}
.#{$linearico-css-prefix}-thumbs-down2:before {
	content: "\e91c";
}
.#{$linearico-css-prefix}-thumbs-up3:before {
	content: "\e91d";
}
.#{$linearico-css-prefix}-thumbs-down3:before {
	content: "\e91e";
}
.#{$linearico-css-prefix}-share:before {
	content: "\e91f";
}
.#{$linearico-css-prefix}-share2:before {
	content: "\e920";
}
.#{$linearico-css-prefix}-share3:before {
	content: "\e921";
}
.#{$linearico-css-prefix}-magnifier:before {
	content: "\e922";
}
.#{$linearico-css-prefix}-file-search:before {
	content: "\e923";
}
.#{$linearico-css-prefix}-find-replace:before {
	content: "\e924";
}
.#{$linearico-css-prefix}-zoom-in:before {
	content: "\e925";
}
.#{$linearico-css-prefix}-zoom-out:before {
	content: "\e926";
}
.#{$linearico-css-prefix}-loupe:before {
	content: "\e927";
}
.#{$linearico-css-prefix}-loupe-zoom-in:before {
	content: "\e928";
}
.#{$linearico-css-prefix}-loupe-zoom-out:before {
	content: "\e929";
}
.#{$linearico-css-prefix}-cross:before {
	content: "\e92a";
}
.#{$linearico-css-prefix}-menu:before {
	content: "\e92b";
}
.#{$linearico-css-prefix}-list:before {
	content: "\e92c";
}
.#{$linearico-css-prefix}-list2:before {
	content: "\e92d";
}
.#{$linearico-css-prefix}-list3:before {
	content: "\e92e";
}
.#{$linearico-css-prefix}-menu2:before {
	content: "\e92f";
}
.#{$linearico-css-prefix}-list4:before {
	content: "\e930";
}
.#{$linearico-css-prefix}-menu3:before {
	content: "\e931";
}
.#{$linearico-css-prefix}-exclamation:before {
	content: "\e932";
}
.#{$linearico-css-prefix}-question:before {
	content: "\e933";
}
.#{$linearico-css-prefix}-check:before {
	content: "\e934";
}
.#{$linearico-css-prefix}-cross2:before {
	content: "\e935";
}
.#{$linearico-css-prefix}-plus:before {
	content: "\e936";
}
.#{$linearico-css-prefix}-minus:before {
	content: "\e937";
}
.#{$linearico-css-prefix}-percent:before {
	content: "\e938";
}
.#{$linearico-css-prefix}-chevron-up:before {
	content: "\e939";
}
.#{$linearico-css-prefix}-chevron-down:before {
	content: "\e93a";
}
.#{$linearico-css-prefix}-chevron-left:before {
	content: "\e93b";
}
.#{$linearico-css-prefix}-chevron-right:before {
	content: "\e93c";
}
.#{$linearico-css-prefix}-chevrons-expand-vertical:before {
	content: "\e93d";
}
.#{$linearico-css-prefix}-chevrons-expand-horizontal:before {
	content: "\e93e";
}
.#{$linearico-css-prefix}-chevrons-contract-vertical:before {
	content: "\e93f";
}
.#{$linearico-css-prefix}-chevrons-contract-horizontal:before {
	content: "\e940";
}
.#{$linearico-css-prefix}-arrow-up:before {
	content: "\e941";
}
.#{$linearico-css-prefix}-arrow-down:before {
	content: "\e942";
}
.#{$linearico-css-prefix}-arrow-left:before {
	content: "\e943";
}
.#{$linearico-css-prefix}-arrow-right:before {
	content: "\e944";
}
.#{$linearico-css-prefix}-arrow-up-right:before {
	content: "\e945";
}
.#{$linearico-css-prefix}-arrows-merge:before {
	content: "\e946";
}
.#{$linearico-css-prefix}-arrows-split:before {
	content: "\e947";
}
.#{$linearico-css-prefix}-arrow-divert:before {
	content: "\e948";
}
.#{$linearico-css-prefix}-arrow-return:before {
	content: "\e949";
}
.#{$linearico-css-prefix}-expand:before {
	content: "\e94a";
}
.#{$linearico-css-prefix}-contract:before {
	content: "\e94b";
}
.#{$linearico-css-prefix}-expand2:before {
	content: "\e94c";
}
.#{$linearico-css-prefix}-contract2:before {
	content: "\e94d";
}
.#{$linearico-css-prefix}-move:before {
	content: "\e94e";
}
.#{$linearico-css-prefix}-tab:before {
	content: "\e94f";
}
.#{$linearico-css-prefix}-arrow-wave:before {
	content: "\e950";
}
.#{$linearico-css-prefix}-expand3:before {
	content: "\e951";
}
.#{$linearico-css-prefix}-expand4:before {
	content: "\e952";
}
.#{$linearico-css-prefix}-contract3:before {
	content: "\e953";
}
.#{$linearico-css-prefix}-notification:before {
	content: "\e954";
}
.#{$linearico-css-prefix}-warning:before {
	content: "\e955";
}
.#{$linearico-css-prefix}-notification-circle:before {
	content: "\e956";
}
.#{$linearico-css-prefix}-question-circle:before {
	content: "\e957";
}
.#{$linearico-css-prefix}-menu-circle:before {
	content: "\e958";
}
.#{$linearico-css-prefix}-checkmark-circle:before {
	content: "\e959";
}
.#{$linearico-css-prefix}-cross-circle:before {
	content: "\e95a";
}
.#{$linearico-css-prefix}-plus-circle:before {
	content: "\e95b";
}
.#{$linearico-css-prefix}-circle-minus:before {
	content: "\e95c";
}
.#{$linearico-css-prefix}-percent-circle:before {
	content: "\e95d";
}
.#{$linearico-css-prefix}-arrow-up-circle:before {
	content: "\e95e";
}
.#{$linearico-css-prefix}-arrow-down-circle:before {
	content: "\e95f";
}
.#{$linearico-css-prefix}-arrow-left-circle:before {
	content: "\e960";
}
.#{$linearico-css-prefix}-arrow-right-circle:before {
	content: "\e961";
}
.#{$linearico-css-prefix}-chevron-up-circle:before {
	content: "\e962";
}
.#{$linearico-css-prefix}-chevron-down-circle:before {
	content: "\e963";
}
.#{$linearico-css-prefix}-chevron-left-circle:before {
	content: "\e964";
}
.#{$linearico-css-prefix}-chevron-right-circle:before {
	content: "\e965";
}
.#{$linearico-css-prefix}-backward-circle:before {
	content: "\e966";
}
.#{$linearico-css-prefix}-first-circle:before {
	content: "\e967";
}
.#{$linearico-css-prefix}-previous-circle:before {
	content: "\e968";
}
.#{$linearico-css-prefix}-stop-circle:before {
	content: "\e969";
}
.#{$linearico-css-prefix}-play-circle:before {
	content: "\e96a";
}
.#{$linearico-css-prefix}-pause-circle:before {
	content: "\e96b";
}
.#{$linearico-css-prefix}-next-circle:before {
	content: "\e96c";
}
.#{$linearico-css-prefix}-last-circle:before {
	content: "\e96d";
}
.#{$linearico-css-prefix}-forward-circle:before {
	content: "\e96e";
}
.#{$linearico-css-prefix}-eject-circle:before {
	content: "\e96f";
}
.#{$linearico-css-prefix}-crop:before {
	content: "\e970";
}
.#{$linearico-css-prefix}-frame-expand:before {
	content: "\e971";
}
.#{$linearico-css-prefix}-frame-contract:before {
	content: "\e972";
}
.#{$linearico-css-prefix}-focus:before {
	content: "\e973";
}
.#{$linearico-css-prefix}-transform:before {
	content: "\e974";
}
.#{$linearico-css-prefix}-grid:before {
	content: "\e975";
}
.#{$linearico-css-prefix}-grid-crossed:before {
	content: "\e976";
}
.#{$linearico-css-prefix}-layers:before {
	content: "\e977";
}
.#{$linearico-css-prefix}-layers-crossed:before {
	content: "\e978";
}
.#{$linearico-css-prefix}-toggle:before {
	content: "\e979";
}
.#{$linearico-css-prefix}-rulers:before {
	content: "\e97a";
}
.#{$linearico-css-prefix}-ruler:before {
	content: "\e97b";
}
.#{$linearico-css-prefix}-funnel:before {
	content: "\e97c";
}
.#{$linearico-css-prefix}-flip-horizontal:before {
	content: "\e97d";
}
.#{$linearico-css-prefix}-flip-vertical:before {
	content: "\e97e";
}
.#{$linearico-css-prefix}-flip-horizontal2:before {
	content: "\e97f";
}
.#{$linearico-css-prefix}-flip-vertical2:before {
	content: "\e980";
}
.#{$linearico-css-prefix}-angle:before {
	content: "\e981";
}
.#{$linearico-css-prefix}-angle2:before {
	content: "\e982";
}
.#{$linearico-css-prefix}-subtract:before {
	content: "\e983";
}
.#{$linearico-css-prefix}-combine:before {
	content: "\e984";
}
.#{$linearico-css-prefix}-intersect:before {
	content: "\e985";
}
.#{$linearico-css-prefix}-exclude:before {
	content: "\e986";
}
.#{$linearico-css-prefix}-align-center-vertical:before {
	content: "\e987";
}
.#{$linearico-css-prefix}-align-right:before {
	content: "\e988";
}
.#{$linearico-css-prefix}-align-bottom:before {
	content: "\e989";
}
.#{$linearico-css-prefix}-align-left:before {
	content: "\e98a";
}
.#{$linearico-css-prefix}-align-center-horizontal:before {
	content: "\e98b";
}
.#{$linearico-css-prefix}-align-top:before {
	content: "\e98c";
}
.#{$linearico-css-prefix}-square:before {
	content: "\e98d";
}
.#{$linearico-css-prefix}-plus-square:before {
	content: "\e98e";
}
.#{$linearico-css-prefix}-minus-square:before {
	content: "\e98f";
}
.#{$linearico-css-prefix}-percent-square:before {
	content: "\e990";
}
.#{$linearico-css-prefix}-arrow-up-square:before {
	content: "\e991";
}
.#{$linearico-css-prefix}-arrow-down-square:before {
	content: "\e992";
}
.#{$linearico-css-prefix}-arrow-left-square:before {
	content: "\e993";
}
.#{$linearico-css-prefix}-arrow-right-square:before {
	content: "\e994";
}
.#{$linearico-css-prefix}-chevron-up-square:before {
	content: "\e995";
}
.#{$linearico-css-prefix}-chevron-down-square:before {
	content: "\e996";
}
.#{$linearico-css-prefix}-chevron-left-square:before {
	content: "\e997";
}
.#{$linearico-css-prefix}-chevron-right-square:before {
	content: "\e998";
}
.#{$linearico-css-prefix}-check-square:before {
	content: "\e999";
}
.#{$linearico-css-prefix}-cross-square:before {
	content: "\e99a";
}
.#{$linearico-css-prefix}-menu-square:before {
	content: "\e99b";
}
.#{$linearico-css-prefix}-prohibited:before {
	content: "\e99c";
}
.#{$linearico-css-prefix}-circle:before {
	content: "\e99d";
}
.#{$linearico-css-prefix}-radio-button:before {
	content: "\e99e";
}
.#{$linearico-css-prefix}-ligature:before {
	content: "\e99f";
}
.#{$linearico-css-prefix}-text-format:before {
	content: "\e9a0";
}
.#{$linearico-css-prefix}-text-format-remove:before {
	content: "\e9a1";
}
.#{$linearico-css-prefix}-text-size:before {
	content: "\e9a2";
}
.#{$linearico-css-prefix}-bold:before {
	content: "\e9a3";
}
.#{$linearico-css-prefix}-italic:before {
	content: "\e9a4";
}
.#{$linearico-css-prefix}-underline:before {
	content: "\e9a5";
}
.#{$linearico-css-prefix}-strikethrough:before {
	content: "\e9a6";
}
.#{$linearico-css-prefix}-highlight:before {
	content: "\e9a7";
}
.#{$linearico-css-prefix}-text-align-left:before {
	content: "\e9a8";
}
.#{$linearico-css-prefix}-text-align-center:before {
	content: "\e9a9";
}
.#{$linearico-css-prefix}-text-align-right:before {
	content: "\e9aa";
}
.#{$linearico-css-prefix}-text-align-justify:before {
	content: "\e9ab";
}
.#{$linearico-css-prefix}-line-spacing:before {
	content: "\e9ac";
}
.#{$linearico-css-prefix}-indent-increase:before {
	content: "\e9ad";
}
.#{$linearico-css-prefix}-indent-decrease:before {
	content: "\e9ae";
}
.#{$linearico-css-prefix}-text-wrap:before {
	content: "\e9af";
}
.#{$linearico-css-prefix}-pilcrow:before {
	content: "\e9b0";
}
.#{$linearico-css-prefix}-direction-ltr:before {
	content: "\e9b1";
}
.#{$linearico-css-prefix}-direction-rtl:before {
	content: "\e9b2";
}
.#{$linearico-css-prefix}-page-break:before {
	content: "\e9b3";
}
.#{$linearico-css-prefix}-page-break2:before {
	content: "\e9b4";
}
.#{$linearico-css-prefix}-sort-alpha-asc:before {
	content: "\e9b5";
}
.#{$linearico-css-prefix}-sort-alpha-desc:before {
	content: "\e9b6";
}
.#{$linearico-css-prefix}-sort-numeric-asc:before {
	content: "\e9b7";
}
.#{$linearico-css-prefix}-sort-numeric-desc:before {
	content: "\e9b8";
}
.#{$linearico-css-prefix}-sort-amount-asc:before {
	content: "\e9b9";
}
.#{$linearico-css-prefix}-sort-amount-desc:before {
	content: "\e9ba";
}
.#{$linearico-css-prefix}-sort-time-asc:before {
	content: "\e9bb";
}
.#{$linearico-css-prefix}-sort-time-desc:before {
	content: "\e9bc";
}
.#{$linearico-css-prefix}-sigma:before {
	content: "\e9bd";
}
.#{$linearico-css-prefix}-pencil-line:before {
	content: "\e9be";
}
.#{$linearico-css-prefix}-hand:before {
	content: "\e9bf";
}
.#{$linearico-css-prefix}-pointer-up:before {
	content: "\e9c0";
}
.#{$linearico-css-prefix}-pointer-right:before {
	content: "\e9c1";
}
.#{$linearico-css-prefix}-pointer-down:before {
	content: "\e9c2";
}
.#{$linearico-css-prefix}-pointer-left:before {
	content: "\e9c3";
}
.#{$linearico-css-prefix}-finger-tap:before {
	content: "\e9c4";
}
.#{$linearico-css-prefix}-fingers-tap:before {
	content: "\e9c5";
}
.#{$linearico-css-prefix}-reminder:before {
	content: "\e9c6";
}
.#{$linearico-css-prefix}-fingers-crossed:before {
	content: "\e9c7";
}
.#{$linearico-css-prefix}-fingers-victory:before {
	content: "\e9c8";
}
.#{$linearico-css-prefix}-gesture-zoom:before {
	content: "\e9c9";
}
.#{$linearico-css-prefix}-gesture-pinch:before {
	content: "\e9ca";
}
.#{$linearico-css-prefix}-fingers-scroll-horizontal:before {
	content: "\e9cb";
}
.#{$linearico-css-prefix}-fingers-scroll-vertical:before {
	content: "\e9cc";
}
.#{$linearico-css-prefix}-fingers-scroll-left:before {
	content: "\e9cd";
}
.#{$linearico-css-prefix}-fingers-scroll-right:before {
	content: "\e9ce";
}
.#{$linearico-css-prefix}-hand2:before {
	content: "\e9cf";
}
.#{$linearico-css-prefix}-pointer-up2:before {
	content: "\e9d0";
}
.#{$linearico-css-prefix}-pointer-right2:before {
	content: "\e9d1";
}
.#{$linearico-css-prefix}-pointer-down2:before {
	content: "\e9d2";
}
.#{$linearico-css-prefix}-pointer-left2:before {
	content: "\e9d3";
}
.#{$linearico-css-prefix}-finger-tap2:before {
	content: "\e9d4";
}
.#{$linearico-css-prefix}-fingers-tap2:before {
	content: "\e9d5";
}
.#{$linearico-css-prefix}-reminder2:before {
	content: "\e9d6";
}
.#{$linearico-css-prefix}-gesture-zoom2:before {
	content: "\e9d7";
}
.#{$linearico-css-prefix}-gesture-pinch2:before {
	content: "\e9d8";
}
.#{$linearico-css-prefix}-fingers-scroll-horizontal2:before {
	content: "\e9d9";
}
.#{$linearico-css-prefix}-fingers-scroll-vertical2:before {
	content: "\e9da";
}
.#{$linearico-css-prefix}-fingers-scroll-left2:before {
	content: "\e9db";
}
.#{$linearico-css-prefix}-fingers-scroll-right2:before {
	content: "\e9dc";
}
.#{$linearico-css-prefix}-fingers-scroll-vertical3:before {
	content: "\e9dd";
}
.#{$linearico-css-prefix}-border-style:before {
	content: "\e9de";
}
.#{$linearico-css-prefix}-border-all:before {
	content: "\e9df";
}
.#{$linearico-css-prefix}-border-outer:before {
	content: "\e9e0";
}
.#{$linearico-css-prefix}-border-inner:before {
	content: "\e9e1";
}
.#{$linearico-css-prefix}-border-top:before {
	content: "\e9e2";
}
.#{$linearico-css-prefix}-border-horizontal:before {
	content: "\e9e3";
}
.#{$linearico-css-prefix}-border-bottom:before {
	content: "\e9e4";
}
.#{$linearico-css-prefix}-border-left:before {
	content: "\e9e5";
}
.#{$linearico-css-prefix}-border-vertical:before {
	content: "\e9e6";
}
.#{$linearico-css-prefix}-border-right:before {
	content: "\e9e7";
}
.#{$linearico-css-prefix}-border-none:before {
	content: "\e9e8";
}
.#{$linearico-css-prefix}-ellipsis:before {
	content: "\e9e9";
}
.#{$linearico-css-prefix}-uni21:before {
	content: "\21";
}
.#{$linearico-css-prefix}-uni22:before {
	content: "\22";
}
.#{$linearico-css-prefix}-uni23:before {
	content: "\23";
}
.#{$linearico-css-prefix}-uni24:before {
	content: "\24";
}
.#{$linearico-css-prefix}-uni25:before {
	content: "\25";
}
.#{$linearico-css-prefix}-uni26:before {
	content: "\26";
}
.#{$linearico-css-prefix}-uni27:before {
	content: "\27";
}
.#{$linearico-css-prefix}-uni28:before {
	content: "\28";
}
.#{$linearico-css-prefix}-uni29:before {
	content: "\29";
}
.#{$linearico-css-prefix}-uni2a:before {
	content: "\2a";
}
.#{$linearico-css-prefix}-uni2b:before {
	content: "\2b";
}
.#{$linearico-css-prefix}-uni2c:before {
	content: "\2c";
}
.#{$linearico-css-prefix}-uni2d:before {
	content: "\2d";
}
.#{$linearico-css-prefix}-uni2e:before {
	content: "\2e";
}
.#{$linearico-css-prefix}-uni2f:before {
	content: "\2f";
}
.#{$linearico-css-prefix}-uni30:before {
	content: "\30";
}
.#{$linearico-css-prefix}-uni31:before {
	content: "\31";
}
.#{$linearico-css-prefix}-uni32:before {
	content: "\32";
}
.#{$linearico-css-prefix}-uni33:before {
	content: "\33";
}
.#{$linearico-css-prefix}-uni34:before {
	content: "\34";
}
.#{$linearico-css-prefix}-uni35:before {
	content: "\35";
}
.#{$linearico-css-prefix}-uni36:before {
	content: "\36";
}
.#{$linearico-css-prefix}-uni37:before {
	content: "\37";
}
.#{$linearico-css-prefix}-uni38:before {
	content: "\38";
}
.#{$linearico-css-prefix}-uni39:before {
	content: "\39";
}
.#{$linearico-css-prefix}-uni3a:before {
	content: "\3a";
}
.#{$linearico-css-prefix}-uni3b:before {
	content: "\3b";
}
.#{$linearico-css-prefix}-uni3c:before {
	content: "\3c";
}
.#{$linearico-css-prefix}-uni3d:before {
	content: "\3d";
}
.#{$linearico-css-prefix}-uni3e:before {
	content: "\3e";
}
.#{$linearico-css-prefix}-uni3f:before {
	content: "\3f";
}
.#{$linearico-css-prefix}-uni40:before {
	content: "\40";
}
.#{$linearico-css-prefix}-uni41:before {
	content: "\41";
}
.#{$linearico-css-prefix}-uni42:before {
	content: "\42";
}
.#{$linearico-css-prefix}-uni43:before {
	content: "\43";
}
.#{$linearico-css-prefix}-uni44:before {
	content: "\44";
}
.#{$linearico-css-prefix}-uni45:before {
	content: "\45";
}
.#{$linearico-css-prefix}-uni46:before {
	content: "\46";
}
.#{$linearico-css-prefix}-uni47:before {
	content: "\47";
}
.#{$linearico-css-prefix}-uni48:before {
	content: "\48";
}
.#{$linearico-css-prefix}-uni49:before {
	content: "\49";
}
.#{$linearico-css-prefix}-uni4a:before {
	content: "\4a";
}
.#{$linearico-css-prefix}-uni4b:before {
	content: "\4b";
}
.#{$linearico-css-prefix}-uni4c:before {
	content: "\4c";
}
.#{$linearico-css-prefix}-uni4d:before {
	content: "\4d";
}
.#{$linearico-css-prefix}-uni4e:before {
	content: "\4e";
}
.#{$linearico-css-prefix}-uni4f:before {
	content: "\4f";
}
.#{$linearico-css-prefix}-uni50:before {
	content: "\50";
}
.#{$linearico-css-prefix}-uni51:before {
	content: "\51";
}
.#{$linearico-css-prefix}-uni52:before {
	content: "\52";
}
.#{$linearico-css-prefix}-uni53:before {
	content: "\53";
}
.#{$linearico-css-prefix}-uni54:before {
	content: "\54";
}
.#{$linearico-css-prefix}-uni55:before {
	content: "\55";
}
.#{$linearico-css-prefix}-uni56:before {
	content: "\56";
}
.#{$linearico-css-prefix}-uni57:before {
	content: "\57";
}
.#{$linearico-css-prefix}-uni58:before {
	content: "\58";
}
.#{$linearico-css-prefix}-uni59:before {
	content: "\59";
}
.#{$linearico-css-prefix}-uni5a:before {
	content: "\5a";
}
.#{$linearico-css-prefix}-uni5b:before {
	content: "\5b";
}
.#{$linearico-css-prefix}-uni5c:before {
	content: "\5c";
}
.#{$linearico-css-prefix}-uni5d:before {
	content: "\5d";
}
.#{$linearico-css-prefix}-uni5e:before {
	content: "\5e";
}
.#{$linearico-css-prefix}-uni5f:before {
	content: "\5f";
}
.#{$linearico-css-prefix}-uni60:before {
	content: "\60";
}
.#{$linearico-css-prefix}-uni61:before {
	content: "\61";
}
.#{$linearico-css-prefix}-uni62:before {
	content: "\62";
}
.#{$linearico-css-prefix}-uni63:before {
	content: "\63";
}
.#{$linearico-css-prefix}-uni64:before {
	content: "\64";
}
.#{$linearico-css-prefix}-uni65:before {
	content: "\65";
}
.#{$linearico-css-prefix}-uni66:before {
	content: "\66";
}
.#{$linearico-css-prefix}-uni67:before {
	content: "\67";
}
.#{$linearico-css-prefix}-uni68:before {
	content: "\68";
}
.#{$linearico-css-prefix}-uni69:before {
	content: "\69";
}
.#{$linearico-css-prefix}-uni6a:before {
	content: "\6a";
}
.#{$linearico-css-prefix}-uni6b:before {
	content: "\6b";
}
.#{$linearico-css-prefix}-uni6c:before {
	content: "\6c";
}
.#{$linearico-css-prefix}-uni6d:before {
	content: "\6d";
}
.#{$linearico-css-prefix}-uni6e:before {
	content: "\6e";
}
.#{$linearico-css-prefix}-uni6f:before {
	content: "\6f";
}
.#{$linearico-css-prefix}-uni70:before {
	content: "\70";
}
.#{$linearico-css-prefix}-uni71:before {
	content: "\71";
}
.#{$linearico-css-prefix}-uni72:before {
	content: "\72";
}
.#{$linearico-css-prefix}-uni73:before {
	content: "\73";
}
.#{$linearico-css-prefix}-uni74:before {
	content: "\74";
}
.#{$linearico-css-prefix}-uni75:before {
	content: "\75";
}
.#{$linearico-css-prefix}-uni76:before {
	content: "\76";
}
.#{$linearico-css-prefix}-uni77:before {
	content: "\77";
}
.#{$linearico-css-prefix}-uni78:before {
	content: "\78";
}
.#{$linearico-css-prefix}-uni79:before {
	content: "\79";
}
.#{$linearico-css-prefix}-uni7a:before {
	content: "\7a";
}
.#{$linearico-css-prefix}-uni7b:before {
	content: "\7b";
}
.#{$linearico-css-prefix}-uni7c:before {
	content: "\7c";
}
.#{$linearico-css-prefix}-uni7d:before {
	content: "\7d";
}
.#{$linearico-css-prefix}-uni7e:before {
	content: "\7e";
}
.#{$linearico-css-prefix}-copyright:before {
	content: "\a9";
}
