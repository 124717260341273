.main-header{
	position: fixed;
	right: 0;
	left: 0;
	z-index: 20;
	@include transition(top 0.2s ease-in-out);
	top: 0;
	/*
	.midnightHeader{
		.logo-bitflow{
			width: 42px;
			height: 40px;
			font-size: 0;
			display: block;
			margin-top: 20px;
		}

		&.red{
			.logo-bitflow{
				@include retina('../img/logo_red.png', 2, 42px 40px, center center no-repeat);
			}

		}

		&.white{
			.logo-bitflow{
				@include retina('../img/logo_white.png', 2, 42px 40px, center center no-repeat);
			}
		}

		&.black{
			.logo-bitflow{
				@include retina('../img/logo_black.png', 2, 42px 40px, center center no-repeat);
			}
		}

		.menu-burger{
			vertical-align: middle;
			@include transition();
			display: none;
			float: right;
			.primary-nav-trigger{
				margin-top: 10px;
				width: 30px;
				height: 30px;
				padding-top: 5px;
				display: block;
				.icon-burger{
					@include burger(25px, 2px, 5px, $base-color-pink);
					@include border-radius(3px);
					width: 15px!important;

					margin-left: 10px;
					&:before,
					&:after{
						@include border-radius(3px);
					}

					&:before{
						left: -9px;
					}

					&:after{
						width: 18px;
						left: -3px;
					}
				}
				&.is-clicked{
					.icon-burger{
						@include burger-to-cross;
						&:before,
						&:after{
							background-color: $base-color-white;
						}


						margin-left: 0;

						&:before{
							left: 0;
						}

						&:after{
							width: 25px;
							left: 0;
						}

					}
				}


			}
		}

		&.white{
			.menu-burger{
				.primary-nav-trigger{
					.icon-burger{
						background-color: $base-color-white;
						&:before,
						&:after{
							background-color: $base-color-white;
						}
					}

				}
			}
		}

		&.black{
			.menu-burger{
				.primary-nav-trigger{
					.icon-burger{
						background-color: $base-color-black;
						&:before,
						&:after{
							background-color: $base-color-black;
						}
					}

				}
			}
		}
	}

	*/

	.logo-bitflow{
		width: 42px;
		height: 40px;
		font-size: 0;
		display: block;
		margin-top: 20px;
		.fp-viewing-hero &{
			@include retina('../img/logo_red.png', 2, 42px 40px, center center no-repeat);
		}

		
		/*
		.fp-viewing-hero &,
		.fp-viewing-contacto &{
			@include retina('../img/logo_red.png', 2, 42px 40px, center center no-repeat);
		}

		.fp-viewing-que-hacemos &{
			@include retina('../img/logo_black.png', 2, 42px 40px, center center no-repeat);
		}

		.fp-viewing-mobile &,
		.fp-viewing-desktop &,
		.fp-viewing-clientes &{
			@include retina('../img/logo_white.png', 2, 42px 40px, center center no-repeat);

		}
		*/

		&.landing{
			width: 178px;
			height: 40px;
			margin-top: 50px;
			.fp-viewing-hero &{
				@include retina('../img/logo_landing.png', 2, 178px 40px, center center no-repeat);
			}
		}
	}

	.menu-burger{
		vertical-align: middle;
		@include transition();
		display: none;
		float: right;
		.primary-nav-trigger{
			margin-top: 10px;
			width: 30px;
			height: 30px;
			padding-top: 5px;
			display: block;
			.icon-burger{
				@include burger(25px, 2px, 5px, $base-color-pink);
				@include border-radius(3px);
				width: 15px!important;

				margin-left: 10px;
				&:before,
				&:after{
					@include border-radius(3px);
				}

				&:before{
					left: -9px;
				}

				&:after{
					width: 18px;
					left: -3px;
				}

				.fp-viewing-hero &,
				.fp-viewing-contacto &{
					&,
					&:before,
					&:after{
						background-color: $base-color-red;
					}
				}

				.fp-viewing-que-hacemos &{
					&,
					&:before,
					&:after{
						background-color: $base-color-black;
					}
				}

				.fp-viewing-mobile &,
				.fp-viewing-desktop &,
				.fp-viewing-clientes &{
					&,
					&:before,
					&:after{
						background-color: $base-color-white;
					}
				}
			}
			&.is-clicked{
				.icon-burger{
					@include burger-to-cross;
					&:before,
					&:after{
						background-color: $base-color-white;
					}


					margin-left: 0;

					&:before{
						left: 0;
					}

					&:after{
						width: 25px;
						left: 0;
					}

				}
			}




		}
	}



	&.menu-is-open {
		/* add a background color to the header when the navigation is open */

		z-index: 100;
		/*
		.midnightHeader{
			.logo-bitflow{

				@include retina('../img/logo_white.png', 2, 42px 40px, center center no-repeat);
			}

		}
		*/
		.logo-bitflow{
			@include retina('../img/logo_white.png', 2, 42px 40px, center center no-repeat);
		}
	}

	&.nav-up {
	    top: -50px;
	}
}


.mouse-scroll{
	display: block;
    cursor: pointer;
    position: absolute;
    margin: auto;
    z-index: 199;
    @include center(x);
    bottom: 5%;
    .mouse{
    	height: 21px;
    	width: 14px;
		@include border-radius(10px);
    	border: 2px solid $base-color-black;
    	top: 170px;
    	.mouse-in{
    		height: 5px;
    		width: 2px;
    		display: block;
    		margin: 5px auto;
    		background: $base-color-black;
    		position: relative;
    		-webkit-animation: animated-mouse 1.2s ease infinite;
    		moz-animation: mouse-animated 1.2s ease infinite;
    	}
    }

    span{
    	display: block;
    	width: 5px;
    	height: 5px;
    	@include transform(rotate(45deg));
    	border-right: 2px solid $base-color-black;
    	border-bottom: 2px solid $base-color-black;
    	margin: 0 0 3px 4.5px;
    }

    .down-arrow-1 {
        margin-top: 6px;
    }

   .down-arrow-1,
   .down-arrow-2,
   .down-arrow-3 {
        -webkit-animation: mouse-scroll 1s infinite;
        -moz-animation: mouse-scroll 1s infinite;
    }

    .down-arrow-1 {
        -webkit-animation-delay: .1s;
        -moz-animation-delay: .1s;
        -webkit-animation-direction: alternate
    }

     .down-arrow-2 {
        -webkit-animation-delay: .2s;
        -moz-animation-delay: .2s;
        -webkit-animation-direction: alternate
    }

    .down-arrow-3 {
        -webkit-animation-delay: .3s;
        -moz-animation-dekay: .3s;
        -webkit-animation-direction: alternate
    }

    @-webkit-keyframes animated-mouse {
        0% {
            opacity: 1;
            -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
            transform: translateY(0)
        }
        100% {
            opacity: 0;
            -webkit-transform: translateY(6px);
            -ms-transform: translateY(6px);
            transform: translateY(6px)
        }
    }

    @-webkit-keyframes mouse-scroll {
        0%,
        100% {
            opacity: 1
        }
        50% {
            opacity: .5
        }
    }

    @keyframes mouse-scroll {
        0% {
            opacity: 0
        }
        50% {
            opacity: .5
        }
        100% {
            opacity: 1
        }
    }


    &.mouse-white{
		.mouse{
			border: 2px solid $base-color-white;
			.mouse-in{
				background: $base-color-white;
			}

		}

		span{

			border-right: 2px solid $base-color-white;
			border-bottom: 2px solid $base-color-white;
		}
    }

}

.main-footer{
	padding: 60px 0;
	.logo-bitflow{
		max-width: 40px;
		margin-bottom: 20px;

	}

	h5{
		@include font-size(16px);
		color: $base-color-gray-footer;
		letter-spacing: 0;
		line-height: 24px;
		font-weight: $extra-bold;
	}

	p{
		@include font-size(16px);
		color: $base-color-gray-footer;
		letter-spacing: 0;
		line-height: 24px;
		margin-bottom: 0;
	}

	.block-footer-copy{
		text-align: right;
		.link-social{
			color: $base-color-gray-footer;
			margin-left: 10px;
			margin-top: 20px;
			margin-bottom: 10px;
			display: inline-block;
		}

		p{
			@include font-size(14px);
			a{
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
}


.tooltip{
	.tooltip-inner {
		background-color: $base-color-pink;
		font-family: $font-primary;
		text-align: left;
		font-weight: 300;
		@include font-size(12px);
		@include border-radius(3px);
	}

	&.right .tooltip-arrow {
	  border-right-color:  $base-color-pink;
	}
}


/* Menu Mobile */

.menu-mobile {
	/* by default it's hidden - on top of the viewport */
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: $primary-color;
	z-index: 200;
	text-align: center;
	padding: 50px 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	pointer-events: none;
	overflow: auto;
	/* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
	-webkit-overflow-scrolling: touch;

	@include transition(opacity 0.3s ease);
	@include opacity(0);

	> ul{
		margin-top: 100px;
		li {
			font-weight: 300;
			margin: .2em 0;
			text-transform: capitalize;
			a {
				display: inline-block;
				padding: .4em 1em;
				@include font-size(24px)
				@include transition(background .2s);
				color: $base-color-white;
				text-decoration: none!important;
				font-family: $font-primary;
				@include border-radius(0.25em);
				text-transform: lowercase;

			}

			&.open{
				> a{
					background-color: $base-color-white;
					color: $primary-color;
				}

			}
			> ul{
				display: none;
			}
		}
	}



	&.is-visible {
		@include opacity(1);
		pointer-events: inherit;
	}


}

#qLoverlay{
	&:before{
		content: '';
		position: absolute;
		display: block;
		width: 100px;
		height: 96px;
		font-size: 0;
		display: block;
		@include center(xy);
		margin-top: -200px;
		@include retina('../img/logo_white.png', 2, 100px 96px, center center no-repeat);
	}

	#typed{
		@include font-size(48px);
		color: $base-color-white;
		letter-spacing: 0;
		line-height: normal;
		position: absolute;
		@include center(xy);
		font-weight: 600;
		margin-top: -60px;
	}
	.queryloader__overlay__percentage{
		margin-top: 10px!important;
	}
}



@media screen and (max-width: $phone-landscape-max) {
	.main-header{

		/*
		.midnightHeader{
			.logo-bitflow{
				width: 32px;
				height: 30px;
				margin-top: 10px;
			}

			&.red{
				.logo-bitflow{
					@include retina('../img/logo_red.png', 2, 32px 30px, center center no-repeat);
				}

			}

			&.white{
				.logo-bitflow{
					@include retina('../img/logo_white.png', 2, 32px 30px, center center no-repeat);
				}
			}
			&.black{
				.logo-bitflow{
					@include retina('../img/logo_black.png', 2, 32px 30px, center center no-repeat);
				}
			}

			.menu-burger{
				display: block;
			}
		}

		*/

		.logo-bitflow{
			width: 32px;
			height: 30px;
			margin-top: 10px;

			.fp-viewing-hero &,
			.fp-viewing-contacto &{
				@include retina('../img/logo_red.png', 2, 32px 30px, center center no-repeat);
				&.landing{
					width: 32px;
					height: 30px;
					margin-top: 10px;
					@include retina('../img/logo_white.png', 2, 32px 30px, center center no-repeat);
				}
			}

			.fp-viewing-que-hacemos &{
				@include retina('../img/logo_black.png', 2, 32px 30px, center center no-repeat);
			}

			.fp-viewing-mobile &,
			.fp-viewing-desktop &,
			.fp-viewing-clientes &{
				@include retina('../img/logo_white.png', 2, 32px 30px, center center no-repeat);
			}

		}


		.menu-burger{
			display: block;
		}

		&.menu-is-open {

			/*
			.midnightHeader{
				.logo-bitflow{

					@include retina('../img/logo_white.png', 2, 32px 30px, center center no-repeat);
				}

			}
			*/

			.logo-bitflow{

				@include retina('../img/logo_white.png', 2, 32px 30px, center center no-repeat);

			}


		}

		&.nav-up {
		    top: -60px;
		}


	}


	.main-footer{
		padding: 30px 0!important;
		text-align: center;
		.logo-bitflow{
			margin-left: auto;
			margin-right: auto;
		}
		.block-footer-copy{
			text-align: center;

		}
	}

	.mouse-scroll{
		display: none;
	}

}
