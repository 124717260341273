/* This stylesheet generated by Transfonter (http://transfonter.org) on December 15, 2016 1:33 PM */
@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i');

@font-face {
	font-family: 'Circular Air';
	src: url('../fonts/CircularAir-Medium.eot');
	src: url('../fonts/CircularAir-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CircularAir-Medium.woff') format('woff'),
		url('../fonts/CircularAir-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Circular Air';
	src: url('../fonts/CircularAir-Black.eot');
	src: url('../fonts/CircularAir-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CircularAir-Black.woff') format('woff'),
		url('../fonts/CircularAir-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Circular Air Book';
	src: url('../fonts/CircularAir-Book.eot');
	src: url('../fonts/CircularAir-Book.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CircularAir-Book.woff') format('woff'),
		url('../fonts/CircularAir-Book.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Circular Air';
	src: url('../fonts/CircularAir-Bold.eot');
	src: url('../fonts/CircularAir-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CircularAir-Bold.woff') format('woff'),
		url('../fonts/CircularAir-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Circular Air';
	src: url('../fonts/CircularAir-Light.eot');
	src: url('../fonts/CircularAir-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CircularAir-Light.woff') format('woff'),
		url('../fonts/CircularAir-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
