$font-primary:  'Circular Air', sans-serif;
$font-secundary: 'Lato', sans-serif;


$transparent: rgba(0, 0, 0, 0);

$base-color-white: #ffffff;
$base-color-black: #303030;
$base-color-red:   #E85A64;
$base-color-green: #40BF50;
$base-color-gray: #606060;
$base-color-gray-footer: #ABB4BD;
$base-color-gray-light: #EEEEEE;
$base-color-pink: #E85A64;

$section-color-hero: #fff;
$section-color-hacemos: #BAC3D2;
$section-color-mobile: #F1453D;
$section-color-desktop: #434350;
$section-color-clientes: #606060;
$section-color-contacto: #EFEFF4;


$client-santander:	#F1453D;
$client-foodcloud:	#00B5CC;
$client-scholas:	#00AD8E;
$client-wallmart: 	#007DC6;
$client-zurich:		#4066B3;
$client-hafele:		#E0DEDF;

$primary-color: $base-color-pink !default;