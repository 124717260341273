$btn-color: #E85A64;

.btn{
	font-weight: $medium;
	@include transition();
	@include transition-property(transform);
	@include transition-duration(.4s);
	@include border-radius(100px);
	min-width: 238px;
	@include font-size(16px);
	letter-spacing: 0.88px;
	padding: 15px;
	&.btn-primary{
		@include button-variant($base-color-white, $btn-color, $btn-color);
	}

	&.btn-line{
		@include button-line($btn-color, $base-color-white, $btn-color);
	}
	
}